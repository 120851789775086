<template>
  <div id="appContainer">
    <admin-bar v-if="isAdmin" :offset.sync="offset"  ></admin-bar>
    <div >
      <div class="master-layout-container" :style="adminMargin"  >
        <master-side-nav @handleSideNavSm="handleSideNavSm" :showQuickSetup="true" :sideNavType="sideNavType" :navLinks="navLinks" :style="adminHeight"></master-side-nav>
        <fade-transition :mode="'out-in'">
          <slot></slot>
        </fade-transition>
      </div>
    </div>
    <sb-splash-screen id="splashScreenStyleWithSideNav"></sb-splash-screen>
     <FullScreenModal
      id="FullScreenModal"
      
      :loading="false"
      :bgColor="'white'"
      :hideSubmitButton="true"
      :hideCloseButton="true"
    >
      <div slot="formContent">
        <div class="form-row">
          <div class="col">
            <h1 class="title">
              <span class="fas fa-users"></span> CartBob
            </h1>
            <!-- <h4 class="subtitle" style="text-align:center">Utilize smart technology with ShipBob.</h4> -->
            <div
              style="text-align:center;max-width:500px"
            >Whoops! We couldn't find an account associated to your login.</div>
          </div>
        </div>
        <div class="form-row">
          <div class="col" style="text-align:center">
        
          </div>
        </div>
      </div>
    </FullScreenModal>
  </div>
</template>
<script>
import SbSplashScreen from '@/components/utils/SbSplashScreen'
import OrdersLinks from '@/models/nav-links'
import MasterSideNav from '@/components/layouts/MasterSideNav'
import AdminBar from '@/components/layouts/AdminBar'
import AppTemplateMixin from './AppTemplateMixin'
import UserService from '@/services/user'  
import FullScreenModal from "@/components/layouts/FullScreenModal";

export default {
  name: 'AppSideNavTemplate',
  components: { SbSplashScreen, MasterSideNav, AdminBar, FullScreenModal },
  mixins: [AppTemplateMixin],
  data () {
    return {
      navLinks: OrdersLinks,
      sideNavSmOpen: false,
      impersonatedUser:{},
      loggedInUser:{}  
    }
  },
    created() {  
     this.loadUser();
    },
  computed: {
    adminHeight () {
      if (this.$mq === 'sm' && !this.sideNavSmOpen) return
      const height = `calc(100vh - ${this.offset}px)`
      return { height }
    },
     isAdmin()
    { 
      return this.loggedInUser.IsAdmin;
    },
  },
  methods: {
    loadUser()
    { 
     
        
    },
    handleSideNavSm (val) {
      this.sideNavSmOpen = val
    }
  }
}
</script>
<style lang="scss">
  #splashScreenStyleWithSideNav {
    position:fixed;
    top:0;
    bottom:0;
    right:0;
    z-index:9999;
    height: 100%;
    @media only screen and (min-width: 0px) {
      left: 0px;
      width: 100%;
    }
    @media only screen and (min-width: 576px) {
      left: 60px;
      width: calc(100% - 60px);
    }
    @media only screen and (min-width: 1024px) {
      left: 256px;
      width: calc(100% - 256px);
    }
  }

  .primary-nav
  {
     background: linear-gradient(to top, #378AC5 22.98%, #2C7BB9 36.28%, #2673B1 52.12%, #2470AF 77.1%) !important;
     background: -webkit-linear-gradient(to top,   #378AC5 22.98%, #2C7BB9 36.28%, #2673B1 52.12%, #2470AF 77.1%) !important;
     background: -moz-linear-gradient(to top, #378AC5 22.98%, #2C7BB9 36.28%, #2673B1 52.12%, #2470AF 77.1%) !important;
  }
  
</style>