<script>
import { Bar } from 'vue-chartjs'
export default {
    extends: Bar,
    props:['data', 'options', 'labels'],
    mounted () {
        this.renderLineChart()
    },
    methods: {
        renderLineChart() {
            this.renderChart({
                labels: this.labels,
                datasets: this.data,
            }, this.options)
        }
    },
    watch: {
        data: function() {      
            this.destroy = true;
            this.renderLineChart();
        }
    }
}
</script>
