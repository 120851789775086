<template>
 <!-- :class="'dashboard-panel-'+panelSize" -->
     <div class="card dashboard-card dashboard-panel-large" >
    <div class="card-body"   >
    <!-- <vs-card class="cardx" style="min-height: 100px"> -->
        <div v-if="!disableHeader">
            <header class="vs-card-header" v-if="!headerSmall">
                <title-component :str="title"  :ttPosition="tooltipPosition" :dataName="dataName"
                :header="header"  :paragraph="paragraph" :paragraph1="paragraph1" :paragraph2="paragraph2" :paragraph3="paragraph3"   :footer="footer"
                />
            </header>
            <header class="vs-card-header" v-else style="padding-bottom: 0px !important">
                <title-component :str="title"   :ttPosition="tooltipPosition" :dataName="dataName"
                :header="header"  :paragraph="paragraph" :paragraph1="paragraph1" :paragraph2="paragraph2" :paragraph3="paragraph3"   :footer="footer"
                />
            </header>
        </div>
        <div>
            <component :is="kindTemplate" :data="data"/>
        </div>
        <div v-if="!disableHeader" slot="footer">
            <vs-row vs-justify="flex-end">
                &nbsp;
            </vs-row>
        </div>
    <!-- </vs-card> -->
     </div>
     </div>
</template>
<script>
export default {
    props:['title', 'data', 'kindTemplate', 'disableHeader', 'header', 'paragraph','paragraph1','paragraph2','paragraph3', 'footer', 'tooltipPosition', 'dataName', 'headerSmall','disableFooter']
}
</script>
<style scoped lang='scss'>

 //see shipbobv2.scss
</style>