<template>
    <div class="comparison-filter">
        <vs-row>
            <vs-col vs-tooltip="'col - 5'" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5" vs-sm="5" vs-xs="5">
                <label>{{caption}}</label>
            </vs-col>
            <vs-col vs-tooltip="'col - 7'" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7" vs-sm="7" vs-xs="7">
                <!-- <vs-select @change="changeComparison" class="selectExample" label="Figuras" v-model="select1">
                    <vs-select-item :key="index" :vs-value="item.value" :vs-text="item.text" v-for="(item,index) in items" />
                </vs-select> -->
                <div class="form-group" style="margin-right: 15px !important;">
                    <select class="form-control" @change="changeComparison" label="Figuras" v-model="select1">
                        <option :key="index" :value=item.value :vs-text="item.text" v-for="(item,index) in items">{{item.text}}</option>>
                    </select>
                </div>
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
export default {
    props:['items', 'selected', 'caption'],
    data() {
        return {
            select1: this.selected,
            isMobile: window.innerWidth <= 835,
        }
    },
    methods: {
        changeComparison(){
            this.$store.commit("setComparison", this.select1);
        }
    },
    mounted(){
        this.$store.commit("setComparison", this.select1);
        this.$nextTick(() => {
            window.addEventListener('resize', () => {
                if(window.innerWidth < 835) {
                    this.isMobile = true;
                } else {
                    this.isMobile = false;
                }
            });
        })
    }
}
</script>

