<template>
 <fade-transition>
 <splash v-if="showSplashScreen" :message="message"></splash>
 </fade-transition>
</template>
<script>
export default {
  name: 'SbSplashScreen',
  computed: {
    showSplashScreen () {
      return false;// this.$shipBobStore.getters['showSplashScreen']
    },
    message () {
      return this.$store.getters['splashScreenMessage']
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
