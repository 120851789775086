const i18n = {
  email: {
    en: 'Email is not valid.',
    es: 'El correo no es válido.'
  },
  presence: {
    en: 'Must be present.',
    es: 'Debe estar presente.'
  },
  password: {
    en: 'Must be at least 6 characters.',
    es: 'Debe tener como mínimo 6 caracteres.'
  },
  website: {
    en: 'Hm, that doesn’t look like a valid website. Can you try again?',
    es: 'La dirección del sitio web no es válida.'
  },
  greaterThan: {
    en: 'Must be greater than',
    es: 'Debe ser mayor que'
  },
  positive: {
    en: 'Must be a positive number.',
    es: 'Debe ser un número positivo.'
  },
  match: {
    en: 'Must match.',
    es: 'Debe coincidir con'
  },
  phone: {
    en: 'Must be a valid phone number.',
    es: 'Debe ser un número de teléfono válido.'
  },
  companyName: {
    en: 'Please enter your company\'s name. If you don\'t have one, just enter your own name'
  }
}

/*
* Small Utils
*/

const minInArray = (arr) => Math.min.apply(Math, arr)

export default class Validate {
  /*
  * validate and validationTypes are the main methods that this class exposes.
  * validationTypes: returns the list of available types this class can validate for
  * validate: takes a type and a value and optional extra validation specific arguments
  *   and will return true or false along with a validation message for a given locale
  *   NOTE: provide the targeted 'locale' in the extra arguments - default is 'en'
  *
  * **to add another validation - add the method with an underscore and add the type: 'methodName' to the _validationMap**
  *
  * validateAll: takes an array of validations (i.e. ['presence', 'email']) and applies them to the passed value
  *   and will return the results ({ true/false, message }) of the first failed validation
  */
  static validate (type, value, args) {
    const { t, metaArgs } = this._extractMetaArgs(type)
    const validation = this._validationMap()[t]
    if (typeof validation !== 'undefined' && typeof validation.func === 'function') {
      const valid = validation.func(value, args, ...metaArgs)
      const message = valid ? '' : this._validationMessage(t, validation, value, args, ...metaArgs)
      return { valid, message }
    }
    console.warn(`${t} is not a supported validation type.`)
  }
  static validationTypes () {
    return Object.keys(this._validationMap())
  }
  static validationLocaleList () {
    return Object.keys(i18n).map(k => {
      if (i18n[k] !== null && typeof i18n[k] === 'object') return {validation: k, locales: Object.keys(i18n[k])}
      return null
    }).filter(t => t !== null)
  }
  static validateAll = (validations, value, args) => {
    let valid = true
    let validationMessage = ''
    for (let validation of validations) {
      let v = Validate.validate(validation, value, args)
      if (!v.valid) {
        valid = v.valid
        validationMessage = v.message
        return { valid, validationMessage }
      }
    }
    return { valid, validationMessage }
  }

  /*
  ******************************************
  *********** Validation Mapping ***********
  ******************************************
  */

  static _validationMap () {
    return {
      email: {
        func: this._validateEmail,
        message: (baseMessage) => baseMessage
      },
      presence: {
        func: this._validatePresence,
        message: (baseMessage) => baseMessage
      },
      password: {
        func: this._validatePassword,
        message: (baseMessage) => baseMessage
      },
      website: {
        func: this._validateWebsite,
        message: (baseMessage) => baseMessage
      },
      greaterThan: {
        func: this._greaterThan,
        message: (baseMessage, value, args, ...meta) => `${baseMessage} ${minInArray(meta.map(m => parseInt(m)))}`
      },
      positive: {
        func: this._positive,
        message: (baseMessage) => baseMessage
      },
      match: {
        func: this._match,
        message: (baseMessage, value, args, ...meta) => `${baseMessage} ${meta.join(' ')}`
      },
      phone: {
        func: this._phone,
        message: (baseMessage) => baseMessage
      },
      companyName: {
        func: this._companyName,
        message: (baseMessage) => baseMessage
      }
    }
  }

  /*
  ******************************************
  *********** Validation Methods ***********
  ******************************************
  */
  static _validateEmail (value) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(value)
  }
  static _validatePresence (value) {
    const defined = typeof value !== 'undefined' && value !== null
    const stringCheck = typeof value === 'string' ? value.length > 0 : true
    return defined && stringCheck
  }
  static _validatePassword (value) {
    return value.length >= 6
  }
  static _validateWebsite (value) {
    const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
    return re.test(value)
  }
  static _greaterThan (value, args, ...meta) {
    const greaterThanValue = typeof meta === 'undefined' ? 0 : minInArray(meta.map(m => parseInt(m)))
    return value > greaterThanValue
  }
  static _positive (value) {
    return value >= 0
  }
  static _match (value, args, ...meta) {
    const { matchValue } = args
    return value === matchValue
  }
  static _phone (value) {
    const re = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/
    return re.test(value)
  }
  static _noOfSkus (value) {
    return value >= 1
  }
  static _whenToStartShipping (value) {
    return value !== ''
  }
  static _companyName (value) {
    const defined = typeof value !== 'undefined' && value !== null
    const stringCheck = typeof value === 'string' ? value.length > 0 : true
    return defined && stringCheck
  }

  /*
  ******************************************
  *********** Validation Helpers ***********
  ******************************************
  */
  static _validationMessage (type, validation, value, args, ...meta) {
    const locale = this._extractLocale(args)
    const baseMessage = this._extractMessageFromJson(type, locale)
    if (typeof baseMessage !== 'undefined') return validation.message(baseMessage, value, args, ...meta)
  }

  static _extractMessageFromJson (type, locale) {
    const localeObj = i18n[type]
    if (typeof localeObj !== 'undefined') {
      const message = localeObj[locale]
      if (typeof message !== 'undefined') {
        return message
      } else {
        console.warn(`language module does not support locale: [${locale}].`)
      }
    } else {
      console.warn(`language module not found for [${type}].`)
    }
  }

  static _extractLocale (args) {
    const { locale } = args
    if (typeof locale !== 'undefined') return locale
    console.warn('[locale] was not provided. Defaulting to en')
    return 'en'
  }

  static _extractMetaArgs (type) {
    const params = type.split(' ')
    const t = params[0]
    const metaArgs = params.slice(1)
    return { t, metaArgs }
  }
}
