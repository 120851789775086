<template>
    <h4 class="d-flex justify-content-between">
        <div class="d-flex align-items-center">          
            {{str}}
               <info  style="margin-left:5px;" v-if="paragraph !== undefined" :header="header"  :paragraph="paragraph" :paragraph1="paragraph1" :paragraph2="paragraph2" :paragraph3="paragraph3"   :footer="footer"></info> 
        </div>
        <div style="display: flex; align-items: center;">
            <div style="margin-right: 10px" v-if="dataName !== undefined">
                <download-excel
                    class = "btn btn-light btn-sm"
                    name="table.csv" type = "csv"
                    :data = "receivedData">
                    <i class="fas far fa-cloud-download-alt"></i>
                    Download
                </download-excel>
            </div>
        </div>
    </h4>
</template>
<script>
export default {
    props: ['str', 'header', 'paragraph','paragraph1','paragraph2','paragraph3', 'footer', 'ttPosition', 'dataName'],
    data() {
        return {
            receivedData: [],
        }
    },
    mounted(){
        this.$root.$on('finishedFetch', () => {
            if(this.dataName !== undefined){
                this.receivedData = this.$store.state[`${this.dataName}`]; 
            }
        });
    }
}
</script>

