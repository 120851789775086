<template>
<fade-transition :mode="'out-in'">
    <component :is="template">
      <router-view></router-view>
    </component>
  </fade-transition>
</template>
<script>
import AppTemplate from '@/components/layouts/AppTemplate'
const AppSideNavTemplate = () => import(/* webpackChunkName: "AppSideNavTemplate" */ '@/components/layouts/AppSideNavTemplate')
export default {
  name: 'shipbobVue',
  computed: {
    template () {   
      if(this.$route.fullPath=="/" || this.$route.fullPath.toLowerCase().includes("/auth/shipbobcallback") || this.$route.fullPath.toLowerCase().includes("/thankyou")
      || this.$route.fullPath.toLowerCase().includes("install/shopifycallback") || this.$route.fullPath.toLowerCase().includes("install/shopify")
      || this.$route.fullPath.toLowerCase().includes("?shop=")
      || this.$route.fullPath.toLowerCase().includes("?hmac=")){
        return AppTemplate;
      }else
      {
        return AppSideNavTemplate;
      } 
    },
    route () {
      return this.$route
    }
  }
}
</script>
<style lang="scss">

    @import '../assets/scss/app.scss';
    @import '../node_modules/toastr/toastr.scss';

    #appContainer {
        width: 100%;
        height: 100%;
    }
</style>
