import HttpWrapper from '../utils/http/httpWrapper'
import AuthTokenUtil from '../utils/http/authToken'
const SHIPBOBV2_API = process.env.VUE_APP_SHIPBOB_APIV2
const SHIPBOB_DASHBOARD = process.env.VUE_APP_SHIPBOB_SITE
const VUE_APP_ENDPOINT = process.env.VUE_APP_ENDPOINT


export default class UserService {
  static noop () {}

  static urls () {
    return {       
      forgotPassword: SHIPBOB_DASHBOARD + '/Account/DoForgotPassword',
      login: SHIPBOB_DASHBOARD + '/Account/ValidateUser',
      logout: SHIPBOB_DASHBOARD + '/Account/LogOut',
      newPassword: SHIPBOB_DASHBOARD + '/Account/ForgotPassword',
      about: SHIPBOBV2_API + '/api/onboarding/qualifier1',
      getDetailedUser: SHIPBOBV2_API + '/api/userData/fetchDetailedUserData',
      getLoggedInAndImpersonatedUser: SHIPBOBV2_API + '/api/userData/fetchLoggedInAndImpersonatedBasicUserData',
      setUserStep: SHIPBOBV2_API + '/api/onboarding/savestep',
      markUserPrelaunch: SHIPBOBV2_API + '/api/onboarding/markUserPrelaunch',
      phone: SHIPBOBV2_API + '/api/onboarding/phone',
      business: SHIPBOBV2_API + '/api/onboarding/qualifier2',
      launchDate: SHIPBOBV2_API + '/api/onboarding/launchdate',
      pricingAssignmentExperiment: SHIPBOBV2_API + '/api/onboarding/pricingAssignmentExperiment', 
      upsertLandingPageEmail: SHIPBOBV2_API + '/api/onboarding/upsertLandingPageEmail',
      removeLandingPageEmail: SHIPBOBV2_API + '/api/onboarding/removeLandingPageEmail'   
    }
  }

  static async forgotPassword (payload) {
    const request = {
      Url: this.urls().forgotPassword,
      Data: {
        ...payload
      }
    }
    return await HttpWrapper.Post(request)
  }

  static async newPassword (payload) {
    const request = {
      Url: this.urls().newPassword,
      Data: {
        ...payload
      }
    }
    return await HttpWrapper.Post(request)
  }

  static async login (payload) {    
    const request = {
      Url: this.urls().login,
      Data: {
        ...payload
      }
    }
    return await HttpWrapper.Post(request)
  }

  static async logout (payload) {
    const request = {
      Url: this.urls().logout,
      Data: {
        ...payload
      }    
    }
    AuthTokenUtil.logout();
    return await HttpWrapper.Post(request)
  } 

  static async getUser () {
    const request = {
      Url: this.urls().getUser
    }
    return await HttpWrapper.Get(request, null, null, true)
  }

  static async getDetailedUser () {
    const request = {
      Url: this.urls().getDetailedUser
    }
    return await HttpWrapper.Get(request, null, null, true)
  }

  static async getLoggedInAndImpersonatedUser () {
    const request = {
      Url: this.urls().getLoggedInAndImpersonatedUser
    }
    return await HttpWrapper.Get(request, null, null, true)
  } 
 

}
