<template>
  <div class="base-footer">
    <slot name="action"></slot>
    <slot name="pagination"></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseFooter'
}
</script>

<style scoped lang="scss">
.base-footer {
  margin-top: 1em;
  border-top: 1px solid #f2f2f3;
  display: flex;
  justify-content: flex-end;
}
</style>
