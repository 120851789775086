<template>
    <div style="margin-right:10px;"> 
        <vs-row class="date-filter">
            <vs-col vs-tooltip="'col-6'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" vs-sm="12" vs-xs="12">
                <vs-row>
                    <vs-col vs-tooltip="'col-6'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="5" vs-sm="5" vs-xs="5">
                        
                    </vs-col>
                    <vs-col vs-tooltip="'col-6'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="7" vs-sm="7" vs-xs="7">
                        <datepicker class="vs-input input-date-sm" v-model="from" :format="customFormatter" @closed="setFrom"></datepicker>
                    </vs-col>
                </vs-row>
            </vs-col>
            <vs-col vs-tooltip="'col-6'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" vs-sm="12" vs-xs="12">
                 <vs-row>
                    <vs-col vs-tooltip="'col-6'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="5" vs-sm="5" vs-xs="5">
                        <span style="margin-right:10px; margin-left:10px">TO</span>
                    </vs-col>
                    <vs-col vs-tooltip="'col-6'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="7" vs-sm="7" vs-xs="7">
                        <datepicker class="vs-input input-date-sm" v-model="to" :format="customFormatter" @closed="setTo" :highlighted="highlighted"></datepicker>
                      </vs-col>
                </vs-row>
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
import moment from 'moment';

export default {
    data() {
        return {
            from: '',
            to: '',
            isMobile: window.innerWidth <= 835,
            highlighted:{}
        }
    },
    methods:{
        customFormatter(date) {
            return moment(date).format('MM/DD/YYYY');
        },
        setFrom(){
            this.$store.commit("setFrom", this.customFormatter(new Date(this.from)));
            
            this.highlighted = {
                from: new Date(this.$store.state.from),
                to: new Date(this.$store.state.to)
            };
        },
        setTo() {
            this.$store.commit("setTo", this.customFormatter(new Date(this.to)));
            
            this.highlighted = {
                from: new Date(this.$store.state.from),
                to: new Date(this.$store.state.to)
            };
        }
    },
    mounted(){
        let date = new Date(); 
        
        this.to = this.customFormatter(date.setDate(date.getDate() ));        
        this.from = this.customFormatter(new Date(date.getFullYear(), date.getMonth(), 1));

        this.$store.commit("setFrom", this.from);
        this.$store.commit("setTo", this.to);

        this.highlighted = {
            from: new Date(this.$store.state.from),
            to: new Date(this.$store.state.to)
        };
    }
}
</script>

