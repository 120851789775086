<template>
    <div v-if="smallScreen" class="sidebar">
      <side-nav-header @openSideNav="openSideNavSm"></side-nav-header>
      <slide-transition>
        <div class="flex-grow" v-if="showSideNav">
          <side-nav-items :showQuickSetup="showQuickSetup" :sideNavType="sideNavType" :navLinks="navLinks"></side-nav-items>
          <side-nav-footer></side-nav-footer>
        </div>
      </slide-transition>
    </div>
    <div v-else class="sidebar full-height">
      <side-nav-header @openSideNav="openSideNavSm"></side-nav-header>
      <div class="flex-grow" v-if="showSideNav">
        <side-nav-items :showQuickSetup="showQuickSetup" :sideNavType="sideNavType" :navLinks="navLinks"></side-nav-items>
        <side-nav-footer></side-nav-footer>
      </div>
    </div>
</template>

<script>
// import { DeepClone } from '../../../utils/deepClone'
import SideNavHeader from './SideNavHeader'
import SideNavItems from './SideNavItems'
import SideNavFooter from './SideNavFooter'

export default {
  name: 'MasterSideNav',
  components: { SideNavHeader, SideNavItems, SideNavFooter },
  props: {
    showQuickSetup: {
      type: Boolean,
      default: true
    },
    enableSideNav: {
      type: Boolean,
      default: true
    },
    enableHeader: {
      type: Boolean,
      default: true
    },
    enableFooter: {
      type: Boolean,
      default: true
    },
    navLinks: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      links: [],
      sideNavType: 'Merchant',
      showSideNavSm: false
    }
  },
  mounted () {
    this.$emit('handleSideNavSm', this.showSideNavSm)
  },
  methods: {
    openSideNavSm () {
      this.showSideNavSm = !this.showSideNavSm
      this.$emit('handleSideNavSm', this.showSideNavSm)
    }
  },
  computed: {
    smallScreen () {
      return this.$mq === 'sm'
    },
    transitionType () {
      if (this.$mq === 'sm') return 'slide-transition'
      return 'div'
    },
    showSideNav () {
      return !this.smallScreen || this.showSideNavSm
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-grow {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.full-height {
  height: 100vh;
}

</style>
