<template>
  <div>
    <div class="master-layout-container">
      <div class="flex-grow">
        <div class="sidebar full-height">
          <side-nav-header></side-nav-header>
          <div class="flex-grow">
            <div class="sidebar-items">
              <nav class="nav flex-column nav-pills primary-nav" id="primary-nav">
                <div class="custom-scroll">
                  <div>
                    <a href="/dashboard" class="nav-link" @click.prevent="callback($event)">
                        <div>
                            <mq-layout :mq="['sm', 'xl', 'xxl']" style="display: inline-block">
                                <span>Dashboard</span>
                            </mq-layout>
                        </div>
                    </a>
                    <a href="#" class="nav-link" @click.prevent="callback($event)">
                        <div>
                            <mq-layout :mq="['sm', 'xl', 'xxl']" style="display: inline-block">
                                <span>Fulfillment</span>
                            </mq-layout>
                        </div>
                    </a>
                    <a href="#" class="nav-link" @click.prevent="callback($event)">
                        <div>
                            <mq-layout :mq="['sm', 'xl', 'xxl']" style="display: inline-block">
                                <span>Inventory</span>
                            </mq-layout>
                        </div>
                    </a>
                    <a href="#" class="nav-link" @click.prevent="callback($event)">
                        <div>
                            <mq-layout :mq="['sm', 'xl', 'xxl']" style="display: inline-block">
                                <span>Accounting</span>
                            </mq-layout>
                        </div>
                    </a>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <fade-transition :mode="'out-in'">
        <slot></slot>
      </fade-transition>
    </div>
  </div>
</template>
<script>
import SideNavHeader from "@/components/layouts/SideNavHeader";
import SideNavFooter from "@/components/layouts/SideNavFooter";

export default {
  data() {
    return {
      active: false,
      defaultIndex: 1
    };
  },
  components: { SideNavHeader, SideNavFooter },
  beforeMount() {
    if (window.innerWidth < 1200) {
      this.active = false;
    } else {
      this.active = true;
    }

    switch (this.$router.currentRoute.fullPath) {
      case "/dashboard":
        this.defaultIndex = "1";
        break;
      case "/fulfillment/sla-exceptions":
        this.defaultIndex = "2.1";
        break;
      case "/shipping/undelivered-shipments":
        this.defaultIndex = "2.2";
        break;
      case "/shipping/performance":
        this.defaultIndex = "3.1";
        break;
      case "/inventory/on-hand":
        this.defaultIndex = "4.1";
        break;
      case "/inventory/reconciliation":
        this.defaultIndex = "4.2";
        break;
      case "/inventory/history":
        this.defaultIndex = "4.3";
        break;
      case "/inventory/sku-performance":
        this.defaultIndex = "4.4";
        break;
      case "/inventory/planning":
        this.defaultIndex = "4.5";
        break;
      case "/inventory/map-and-distribution":
        this.defaultIndex = "4.6";
        break;
      case "/accounting/profitability":
        this.defaultIndex = "5.1";
        break;
      case "/accounting/break-down":
        this.defaultIndex = "5.2";
        break;
      case "accounting/storage":
        this.defaultIndex = "5.3";
        break;
    }
  },
  mounted() {
    if (window.innerWidth < 1200) {
      this.active = false;
    } else {
      this.active = true;
    }

    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        if (window.innerWidth < 1200) {
          this.active = false;
        } else {
          this.active = true;
        }
      });
    });
  }
};
</script>


