import json2csv from "csvjson-json2csv"; 
export default class ExportCSVHelper  { 
   static export(requestedFileName,receivedData){  
            let prefix  = "data:text/csv;charset=utf-8,";
            const fileName =  requestedFileName + ".csv";
            var csvContent  = json2csv(receivedData);      
            const data = prefix + encodeURIComponent(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", fileName);
            link.click();
    }
}