export default {
    name: 'onfocus',
    directive: {
      bind: function (el, binding, vNode) {
        const handler = (e) => binding.value()
        el.__vueFocus = handler
        el.addEventListener('focus', handler)
      },
      unbind: function (el, binding) {
        el.removeEventListener('focus', el.__vueFocus)
        el.__vueFocus = null
      }
    }
  }
  