// import { HttpUtil } from '../utils/http'
import HttpWrapper from '../utils/http/httpWrapper'
//import ShipbobAppSettings from '../appSettings/appSetting'
import AuthTokenUtil from '../utils/http/authToken'

export default class AuthorizationService {
  static noop () {}

  static urls () {
    return {
      refreshToken: process.env.VUE_APP_SHIPBOB_SITE + '/api/account/refreshToken',
      // refreshToken: ShipbobAppSettings.refresh + '/api/user/refreshToken',
      authenticate: process.env.VUE_APP_SHIPBOB_AUTHENTICATE + '/connect/accesstokenintrospectionvalidation',
      authenticateCode: process.env.VUE_APP_ENDPOINT + 'auth/shipbobcallback'
    }
  }
  static async refreshToken () {
    const { refreshtoken: refreshToken } = AuthTokenUtil.extractAuth()
    if (typeof refreshToken === 'undefined') return false
    const request = {
      // Url: `${this.urls().refreshToken}?refreshToken=${refreshToken}`
      Url: this.urls().refreshToken,
      Params: { refreshToken }
    }
    return await HttpWrapper.Get(request, null, null, true)
  }

  static async authenticate () {
    const { accesstoken: token } = AuthTokenUtil.extractAuth()
    if (typeof token === 'undefined') return false
    const request = {
      Url: `${this.urls().authenticate}?token=${token}`
    }
    return await HttpWrapper.Get(request, null, null, true)
  }
  static async authenticateCode (code) { 
    const request = {
      Url: `${this.urls().authenticateCode}?code=${code.code}`,
      Data: {
        ...{
          code: code.code,
          id_token: code.id_token
        }
      }
    }
    return await HttpWrapper.Post(request)
  }
}
