<template>
    <div>
        <div class="mobile-navbar">
            <vs-row vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
                    <vs-button class="toggle-sidebar" vs-justify="flex-start" @click="active = !active"><i class="fas fa-bars"></i></vs-button>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="11">
                    <vs-avatar size="45px" vs-justify="center" src="/img/logo.png" style="background:transparent"/>
                </vs-col>
            </vs-row>
        </div>
        <vs-sidebar :default-index="defaultIndex" color="dark" class="sidebarx" spacer v-model="active" :hidden-background="true">
            <vs-row class="header-sidebar" slot="header" vs-justify="center">
                <vs-avatar size="45px" src="/img/logo.png" style="background:transparent"/>
            </vs-row>
            <vs-sidebar-item index="1" to="/dashboard" v-model="defaultIndex">
                <i class="fas fa-tachometer-alt" style="margin-right: 10px"></i>Dashboard 
            </vs-sidebar-item>
            <vs-sidebar-group :icon="'fas fa-shopping-cart'" :title='"Fulfillment"' :open="true">
                <vs-sidebar-item index="2.1" to="/fulfillment/sla-exceptions" v-model="defaultIndex">
                    <i style="margin-right: 10px" ></i>SLA & Exceptions
                </vs-sidebar-item>
                <vs-sidebar-item index="2.2" to="/shipping/undelivered-shipments" v-model="defaultIndex">
                    <i style="margin-right: 10px"></i>Undelivered Shipments
                </vs-sidebar-item>
            </vs-sidebar-group>
            <vs-sidebar-group :title='"Shipping"' :open="true">
                <vs-sidebar-item index="3.1" to="/shipping/performance" v-model="defaultIndex">
                    <i style="margin-right: 10px" ></i>Performance
                </vs-sidebar-item>
            </vs-sidebar-group>
            <vs-sidebar-group :icon="'fa-tag'" :title='"Inventory"' :open="true">
                <vs-sidebar-item index="4.1" to="/inventory/on-hand" v-model="defaultIndex">
                    <i style="margin-right: 10px" ></i>On hand
                </vs-sidebar-item>
                <vs-sidebar-item index="4.2" to="/inventory/reconciliation" v-model="defaultIndex">
                    <i style="margin-right: 10px" ></i>Reconciliation
                </vs-sidebar-item>
                <vs-sidebar-item index="4.3" to="/inventory/history" v-model="defaultIndex">
                    <i style="margin-right: 10px" ></i>Lot History
                </vs-sidebar-item>
                <vs-sidebar-item index="4.4" to="/inventory/sku-performance" v-model="defaultIndex">
                    <i style="margin-right: 10px" ></i>SKU Performance
                </vs-sidebar-item>
                <vs-sidebar-item index="4.5" to="/inventory/planning" v-model="defaultIndex">
                    <i style="margin-right: 10px" ></i>Planning
                </vs-sidebar-item>
                <vs-sidebar-item index="4.6" to="/inventory/map-and-distribution" v-model="defaultIndex">
                    <i style="margin-right: 10px" ></i>Map and Distribution
                </vs-sidebar-item>
            </vs-sidebar-group>
            <vs-sidebar-group :icon="'fa-tag'" :title='"Accounting"' :open="true">
                <vs-sidebar-item index="5.1" to="/accounting/profitability"  v-model="defaultIndex">
                    <i style="margin-right: 10px"></i>Shopify Profitability
                </vs-sidebar-item>
                <vs-sidebar-item index="5.2" to="/accounting/break-down"  v-model="defaultIndex">
                    <i style="margin-right: 10px"></i>Billing Breakdown
                </vs-sidebar-item>
                <vs-sidebar-item index="5.3" to="/accounting/storage"  v-model="defaultIndex">
                    <i style="margin-right: 10px"></i>Storage Costs
                </vs-sidebar-item>
            </vs-sidebar-group>
        </vs-sidebar>
    </div>
</template>
<script>
export default {
    data() {
        return {
            active: false,
            defaultIndex: 1
        }
    },
    beforeMount() {
        if(window.innerWidth < 1200) {
            this.active = false;
        } else {
            this.active = true;
        }

        switch(this.$router.currentRoute.fullPath){
            case '/dashboard': this.defaultIndex = '1'; break;
            case '/fulfillment/sla-exceptions': this.defaultIndex = '2.1'; break;
            case '/shipping/undelivered-shipments': this.defaultIndex = '2.2'; break;
            case '/shipping/performance': this.defaultIndex = '3.1'; break;
            case '/inventory/on-hand': this.defaultIndex = '4.1'; break;
            case '/inventory/reconciliation': this.defaultIndex = '4.2'; break;
            case '/inventory/history': this.defaultIndex = '4.3'; break;
            case '/inventory/sku-performance': this.defaultIndex = '4.4'; break;
            case '/inventory/planning': this.defaultIndex = '4.5'; break;
            case '/inventory/map-and-distribution': this.defaultIndex = '4.6'; break;
            case '/accounting/profitability': this.defaultIndex = '5.1'; break;
            case '/accounting/break-down': this.defaultIndex = '5.2'; break;
            case 'accounting/storage': this.defaultIndex = '5.3'; break;
        }
    },
    mounted() {
        if(window.innerWidth < 1200) {
            this.active = false;
        } else {
            this.active = true;
        }

        this.$nextTick(() => {
            window.addEventListener('resize', () => {
                if(window.innerWidth < 1200) {
                    this.active = false;
                } else {
                    this.active = true;
                }
            });
        })
    }
}
</script>


