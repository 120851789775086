<template>
  <div class="container-fluid flex-full-height">
    <div class="flex-1 d-flex">
      <div id="hero-image-wrapper" class="row instructions">
        <div class="col-12 flex-full-height px-75 py-50 setup-guide-hero">
          <div class="flex-full-height">
            <div class="flex-1 d-flex flex-column">
              <div class="row">
                <h1 class="hero-header bold font-weight-bold line-height-56">
                  <br />
                </h1>
                <h2 class="hero-header-2"></h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div   class="row setup-guide-login-wrapper">
        <div
          class="form-container col-12  justify-content-end px-20 px-l-40 px-xxl-100 py-30"
        >
          <div class="row flex-1 flex-column justify-content-center">
            <img class="shipbob-logo" :src="require('../assets/CartBob-Logo-WName-Color.png')" />
            <h1 class="title">
              <span></span>Thank you!
            </h1>
            <sb-form>
                <sb-button    @click="gotoDashboard()" variation="primary" size="medium" @click.prevent>&nbsp;&nbsp;&nbsp;Go to ShipBob dashboard&nbsp;&nbsp;&nbsp;</sb-button>
                <br/>
                <br/>
                <sb-button    @click="gotoShopifyShippingPage()" variation="secondary" size="medium" @click.prevent>&nbsp;&nbsp;&nbsp;Go to Shopify shipping settings&nbsp;&nbsp;&nbsp;</sb-button>
            <br/>
                <br/>
                <sb-button    @click="gotoSetupGuide()" variation="tertiary" size="medium" @click.prevent>&nbsp;&nbsp;&nbsp;Read Setup Guide&nbsp;&nbsp;&nbsp;</sb-button>
            
            </sb-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import toastr from "toastr";
import axiosInstance from "../helpers/ws";

export default {
  data() {
    return {
      storeName: "",
      WEBAPPURL:process.env.VUE_APP_SHIPBOB_SITE,
      SHOPIFYSHIPPINGPAGE: process.env.VUE_APP_ShippingPagePath,
      ShopifyStoreName:''
    };
  },
  mounted() { 
    var item = this.extractInstallFromLocal();
    this.ShopifyStoreName = item.storeName; 
    this.completeLink(item); 
  },
  computed: {}, 
  methods: {
    gotoDashboard()
  {
    window.location.replace(this.WEBAPPURL);
  },
  gotoShopifyShippingPage()
  {
    window.location.replace('https://'+this.ShopifyStoreName+'.myshopify.com' + this.SHOPIFYSHIPPINGPAGE);
  },
   gotoSetupGuide()
  {
    window.location.replace('https://support.shipbob.com/en/articles/4178165-cartbob-shipbob-s-real-time-rate-app');
  },
    extractInstallFromLocal() {
      try {
        return JSON.parse(window.localStorage.getItem("install")) || {};
      } catch (e) {
        window.localStorage.removeItem("install");
        return {};
      }
    },
    finishLinking()
    { 
         window.localStorage.removeItem("install");
          window.localStorage.removeItem("shop");
    },
    completeLink(item) {
      
      if (item==undefined || 
          item.storeName==undefined){
            return;
      }
      var config = {
        appIdentifier: item.appIdentifier,
        shopName: item.storeName
      };

      axiosInstance(
        "POST",
        process.env.VUE_APP_SHOPIFY_ENDPOINT + "install/linkaccount",
        null,
        config
      )
        .catch(function(error) { 
          Vue.$toast.open({
            message: " Error! " + error.response.data.error,
            type: "error",
            position: "top-right",
            duration: 10000
          });  
        })
        .then(response => {
         this.finishLinking();
          if (response.status === 200) {
            Vue.$toast.open({
              message: "Success!",
              type: "success",
              position: "top-right",
              duration: 10000
            });
          } else {
            Vue.$toast.open({
              message: " Error!",
              type: "error",
              position: "top-right",
              duration: 10000
            });
          }
        });
    }
  }
};
</script>
<style scoped lang='scss'>
.shipbob-logo {
  width: 43%;
}
.setup-guide-login-wrapper{
margin-top:200px;
margin-bottom:auto;
margin-left:auto;
margin-right:auto;
width: 35%;
}
</style>