<template>
    <div>
        <vs-row vs-w="12">
            <vs-col vs-w="12" vs-justify="flex-start">
                <h2 class="h3" style="text-align:center" :class="colorClass"><span v-if="simbol !== 'undefined'">{{simbol}}</span>{{numberWithCommas(min)}}<span class="h2-sub"> Min</span></h2>
                <h2 class="h3" style="text-align:center" :class="colorClass"><span v-if="simbol !== 'undefined'">{{simbol}}</span>{{numberWithCommas(max)}}<span class="h2-sub"> Max</span></h2>
                <p class="h2-sub" style="text-align:center">{{subtitle}}</p>
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
export default {
    props:['min', 'max',  'subtitle', 'simbol', 'colorClass'],
    methods: {
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
}
</script>

