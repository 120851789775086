<template>
  <resize-observer :breakpoints="breakpoints">
    <sb-pagination
      slot-scope="{ el }"
      @fetchPage="handleFetchPage"
      :class="el.is"
      class="pagination-wrapper"
      :pageSize="pageSize"
      :currentPage="currentPage"
      :totalPages="totalPages"
      :totalRecords="totalRecords"
      :visibleRecords="visibleRecords"
    >
      <sb-pagination-links slot="pagination-links" :range="calculateRange(el)">
        <sb-pagination-link slot="page-link-previous">Previous</sb-pagination-link>
        <sb-pagination-link slot="page-links" slot-scope="{ page }">{{ page }}</sb-pagination-link>
        <sb-pagination-link slot="page-link-next">Next</sb-pagination-link>
      </sb-pagination-links>
      <sb-pagination-text
        slot="pagination-text"
        slot-scope="{ currentPage, totalPages, totalRecords, visibleRecords }"
      >
        {{ constructPaginationText(el, currentPage, totalPages, totalRecords, visibleRecords) }}
      </sb-pagination-text>
      
      <sb-pagination-input slot="pagination-input" :labelText="constructGoToText(el)"   />
      
    </sb-pagination>
  </resize-observer>
</template>

<script>
export default {
  name: 'PaginationWrapper',
  props: {
    pageSize: {
      type: Number,
      default: null
    },
    currentPage: {
      type: Number,
      default: null
    },
    totalPages: {
      type: Number,
      default: null
    },
    totalRecords: {
      type: Number,
      default: null
    },
    visibleRecords: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    breakpoints: {
      xs: el => el.width < 450,
      sm: el => el.width >= 450 && el.width < 650,
      md: el => el.width >= 650 && el.width < 700,
      lg: el => el.width >= 700 && el.width < 900,
      xl: el => el.width >= 900
    } 
  }),
  methods: {
    handleFetchPage (args) {
      this.$emit('fetchPage', args)
    },
    calculateRange (el) {
      if (el.is.xl) return 5
      return 3
    },
    constructPaginationText (el, currentPage, totalPages, totalRecords, visibleRecords) {
      if (el.is.lg || el.is.xl) return `Page ${currentPage} of ${totalPages} | Showing ${visibleRecords} of ${totalRecords} |`
      else if (el.is.md) return `${totalPages} Pages | ${totalRecords} Records |`
      return ''
    },
    constructGoToText (el) {
      if (el.is.lg || el.is.xl) return `Go to page: `
      else if (el.is.md) return `Go to page: `      
      else if (el.is.sm) return 'Go to: ' 
      return ""
    } 
  }
}
</script>

<style lang="scss">
.pagination-wrapper {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 0rem;
  padding-right: 0rem;
  box-sizing: border-box;
}
.sb-pagination.xs {
  
  .pagination-text-wrapper {
    flex-basis: 100%;
  }
  .pagination-container:last-child {
    margin-top: 1em;
  }
}

</style>
