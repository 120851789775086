 import { PendoWrapper } from '@/utils/pendoWrapper' 
 
class InternalThirdPartyToolsManager {
  constructor () {
    this.initialized = false

    this.userData = null
    this.appSettings = null 
    this.PendoWrapper = PendoWrapper 
  }

  async init ({ appSettings, userData }) {
    if (userData && appSettings) {
      this.appSettings = appSettings
      this.userData = userData
      this.initialized = true
 
      //this._initializePendo()
    } else {
      console.warn('[ThirdPartyToolsManager]: please provide userData')
    }
  }

  routeTo (routeName) {
   
  }

  async _initializeSignalR () {
    //if (!this.SignalRWrapper.initRun) await SignalRWrapper.init()
   //if (!this.SignalRWrapper.setupRun) SignalRWrapper.setup({})
  }

  async _initializePendo () {
    if (this._shouldEnablePendo) this.PendoWrapper.init(this.userData)
  }

  get _shouldEnablePendo () { 
    return true;
    const featureFlag = true
    if (!featureFlag) return false
    const userData = this.userData
    const isImpersonatingUser = userData.id !== userData.impersonatedUserId
    return !userData.isAdmin || (userData.isAdmin && !isImpersonatingUser)
  } 
}

export const ThirdPartyToolsManager = new InternalThirdPartyToolsManager()
