import Vue from 'vue';
import Router from 'vue-router';
import Login from './components/Login';
import DashboardV2 from './components/DashboardV2';   
import CallBack from './components/CallBack';
import InstallShopify from './components/InstallShopify';
import ThankYouShopify from './components/ThankYouShopify';
import ThankYou from './components/ThankYou';
import store from './store';
import { ThirdPartyToolsManager } from '@/utils/thirdPartyToolsManager';

Vue.use(Router);

let router = new Router({
    mode:'history',
    routes: [      
        {
            path: '/',          
            name: 'Login',
            component: Login,             
        },        
        {
          path: '/auth/shipBobCallBack',
          component: CallBack,
          name: 'CallBack'
        },
        {
          path: '/install/shopifyCallBack',
          component: InstallShopify,
          name: 'InstallShopifyCallBack'
        },
        {
          path: '/auth/shopify',
          component: InstallShopify,
          name: 'AuthShopify'
        },
        {
          path: '/install/shopify',
          component: InstallShopify,
          name: 'InstallShopify'
        },
        {
          path: '/thankyou',
          component: ThankYou,
          name: 'ThankYou'
        },
        {
          path: '/thankyoushopify',
          component: ThankYouShopify,
          name: 'ThankYouShopify'
        },
        {
          path: '/dashboard',
          component: DashboardV2,
          name: 'Dashboard'
        } 
    ]
});

const authenticated = async (routeTo) => { 
    if (store.state.authenticated) {
      return true
    }
    return await  store.dispatch('authenticate')
  }


const setupSettings = async (routeTo) => {
    if (!ThirdPartyToolsManager.initialized) { 
      ThirdPartyToolsManager.init({
        appSettings: { pendoOn: true},
        userData: await store.dispatch('fetchDetailedUser')
      })
    }
    ThirdPartyToolsManager.routeTo(routeTo)
}

router.beforeEach(async (to, from, next) => {
   const routeFrom = from.name
   const routeTo = to.name 
 if(await authenticated(routeTo)){
      await setupSettings(routeTo)
    }
    next()
})
  

export default router;