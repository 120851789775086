<template>
  <div class="container-fluid flex-full-height">
    <div class="flex-1 d-flex">
      <div id="hero-image-wrapper" class="row">
        <div class="col-12 flex-full-height px-75 py-50 setup-guide-hero">
          <div class="flex-full-height">
            <div class="flex-1 d-flex flex-column">
              <div class="row">
                <h1 class="hero-header bold font-weight-bold line-height-56">
                  <br />
                </h1>
                <h2 class="hero-header-2"></h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="setup-guide-login-wrapper" class="row">
        <div
          class="form-container col-12 flex-full-height justify-content-end px-20 px-l-40 px-xxl-100 py-30"
        >
          <div class="row flex-1 flex-column justify-content-center">
            <img class="shipbob-logo" :src="require('../assets/CartBob-Logo-WName-Color.png')" />
            <!-- <h1 class="title"><span class="fas fa-tag"></span>&nbsp;CartBob </h1> -->
            <h4 class="subtitle">Smarter Shipping Rates</h4>
            <vs-row v-if="!authenticated">
              <sb-form>
                <div>
                  <br />
                </div>

                <!-- <sb-button @click="connectTo" variation="primary" size="large" @click.prevent>&nbsp;&nbsp;&nbsp;Connect App&nbsp;&nbsp;&nbsp;</sb-button> -->
              </sb-form>
            </vs-row>
            <vs-row v-else>
              <sb-form>
                <sb-field>
                  <sb-label>Email Address</sb-label>
                  <sb-text-input v-model="email"></sb-text-input>
                </sb-field>
                <div>
                  <br />
                </div>
                <sb-field>
                  <sb-label>Password</sb-label>
                  <sb-text-input type="password" v-model="password"></sb-text-input>
                </sb-field>
                <div>
                  <br />
                </div>

                <!-- <sb-button @click="login" variation="primary" size="medium" @click.prevent>Login</sb-button> -->
              </sb-form>
            </vs-row>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import toastr from "toastr";
import axiosInstance from "../helpers/ws";

export default {
  data() {
    return {
      initialLoad: true,
      loading: true,
      loginLink: ""
    };
  },

  mounted() {
   // this.checkDispatchShopifyInstall();
  },
  computed: {
    user() {
      return this.$store.getters["user/getUser"];
    },
    authenticated() {
      return this.$store.state.authenticated;
    },
    imageWrapper() {
      return "hero-image-wrapper";
    },
    loginWrapper() {
      return "setup-guide-login-wrapper";
    },
    image() {
      return {
        "setup-guide-hero": true,
        "call-us-hero": false
      };
    },
    email: {
      get() {
        return this.user.email.value;
      },
      set(val) {
        this.$store.dispatch("user/setEmail", val);
      }
    },
    password: {
      get() {
        return this.user.password.value;
      },
      set(val) {
        this.$store.dispatch("user/setPassword", val);
      }
    }
  },
  methods: {
    auth() {
      this.$router.push("/dashboard");
    },
    setInitialLoad() {
      if (!this.initialLoad) return;
      this.initialLoad = false;
    },
    async login() {
      this.setInitialLoad();
      if (!this.user.isValid("login")) {
        toastr.error("Invalid/username or password...");
        return;
      }
      this.toggleLoadScreen();
      const success = await this.$store.dispatch(
        "user/login",
        this.user.mapForLogin()
      );
      if (success) {
        this.initialLoad = true;
        this.password = null;
        this.$router.push("/dashboard");
      } else {
        this.toggleLoadScreen();
      }
    },
    toggleLoadScreen() {
      //oginLayout.toggleLoadScreen()
    },
    connectTo() {
      window.location = this.loginLink;
    },
    // checkDispatchShopifyInstall() { 
    //   if (
    //     this.$route.query.shop != undefined &&
    //     this.$route.query.shop != undefined && this.$route.query.shop != "" &&
    //     this.$route.query.hmac != undefined &&
    //     this.$route.query.shop.toLowerCase().includes("myshopify")
    //   ) {                
    //     this.$router.push("/install/shopify?" + Object.keys(this.$route.query).map(key => key + '=' + this.$route.query[key]).join('&'));
    //     return;
    //   }else{
    //     this.$router.push("/");
    //   }
    // }
  }
};
</script>
<style scoped lang='scss'>
.title {
  line-height: 1.5em;
  font-weight: 700;
  color: #3a4b5e;
  padding-left: 0px;
}
.subtitle {
  color: rgb(115, 120, 131);
}
.link-more-details-Login {
  color: rgb(196, 197, 199);
}
.hero-header {
  color: white;
  font-weight: 700;
}
.hero-header-2 {
  color: white;
  font-weight: 500;
}
.shipbob-logo {
  width: 63%;
}
</style>