/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue';
import router from './router';
import Vuesax from 'vuesax';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMq from 'vue-mq'
import Vuetable from 'vuetable-2';
import vSelect from 'vue-select';
import bForm from 'bootstrap-vue/es/components/form/form';
import bInputGroup from 'bootstrap-vue/es/components/input-group/input-group';
import bFormGroup from 'bootstrap-vue/es/components/form-group/form-group';
import bFormInput from 'bootstrap-vue/es/components/form-input/form-input';
import bFormCheckboxGroup from 'bootstrap-vue/es/components/form-checkbox/form-checkbox-group';
import bFormCheckbox from 'bootstrap-vue/es/components/form-checkbox/form-checkbox'; 
import VueToast from 'vue-toast-notification';

// Import the Design System
import DesignSystem from 'shipbob-design-system';
import 'shipbob-design-system/dist/bootstrap/sb-bootstrap.css'
import 'shipbob-design-system/dist/system/system.css'
import('shipbob-design-system/dist/svgs/svgs.js')

//plugins
import CustomDirectives from './plugins/custom-directives-plugin'
import LayoutsPlugin from './plugins/layouts-plugin'
import JsonExcel from 'vue-json-excel'

//generic components
import Info from './components/shared/info';
import Sidebar from './components/shared/Sidebar';
import SidebarV2 from './components/shared/SidebarV2'; 
import CardFullWidth from './components/shared/CardFullWidth'; 
import CardHalfWidth from './components/shared/CardHalfWidth';   
import Title from './components/shared/Title';
import LineChart from './components/shared/LineChart';
import StackedBarChart from './components/shared/StackedBarChart';
import StackedHorizontalBarChart from './components/shared/StackedHorizontalBarChart';
import DoughnutChart from './components/shared/DoughnutChart';
import SearchBar from './components/shared/SearchBar';
import SearchBarSimple from './components/shared/SearchBarSimple';
import Accotation from './components/shared/Accotation';
import AccotationWithoutDoughnut from './components/shared/AccotationWithoutDoughnut';
import Datepicker from 'vuejs-datepicker';
import DateFilter from './components/shared/DateFilter';
import FromDateFilter from './components/shared/FromDateFilter';
import ToDateFilter from './components/shared/ToDateFilter';
import DateFilter7Days from './components/shared/DateFilter7Days';
import ComparisonFilter from './components/shared/ComparisonFilter';
import ComparisonFilterNoCaption from './components/shared/ComparisonFilterNoCaption';
import IndicatorWithSubtitle from './components/shared/IndicatorWithSubtitle';
import IndicatorWithSubtitleDate from './components/shared/IndicatorWithSubtitleDate';
import IndicatorWithSubtitleH2 from './components/shared/IndicatorWithSubtitleH2';
import IndicatorWithSubtitleH3 from './components/shared/IndicatorWithSubtitleH3';
import IndicatorWithSubtitleMinMax from './components/shared/IndicatorWithSubtitleMinMax';
import IndicatorWithSubtitleAndDelta from './components/shared/IndicatorWithSubtitleAndDelta';
import IndicatorWithTitle from './components/shared/IndicatorWithTitle';
import SupBreadcrumb from './components/shared/SupBreadcrumb';
import TableIndicators from './components/shared/TableIndicators';
import PopOver from './components/utils/PopOver'; 
import DownloadDataButton from './components/shared/DownloadDataButton';

//layout components
import MasterSideNav from './components/layouts/MasterSideNav';
import AppSideNavTemplate from './components/layouts/AppSideNavTemplate';
import AppTemplate from './components/layouts/AppTemplate'; 
import CustomVSSidebarGroup from './components/shared/CustomVSSidebarGroup';

//custom components  
 

 //css imports
import 'vuesax/dist/vuesax.css'
import 'material-icons/iconfont/material-icons.css';
import 'vue-toast-notification/dist/index.css';
import './styles/shipbobv2.scss'
import store from './store'

Vue.config.productionTip = false 
Vue.use(CustomDirectives)
Vue.use(LayoutsPlugin)
Vue.use(Vuesax)
Vue.use(Vuetable)
Vue.use(BootstrapVue);
Vue.use(VueToast);
Vue.use(VueMq, {
  breakpoints: { // set breakpoints
    xs: 0,
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1366,
    xxl: Infinity
  }
});
Vue.use(DesignSystem)

Vue.use(VueAxios, axios)
Vue.component('info', Info)
Vue.component('sidebar', Sidebar)
Vue.component('sidebarv2', SidebarV2)
Vue.component('line-chart', LineChart)
Vue.component('stacked-bar-chart', StackedBarChart);
Vue.component('stacked-horizontal-bar-chart', StackedHorizontalBarChart);
Vue.component('doughnut-chart', DoughnutChart);
Vue.component('datepicker', Datepicker)
Vue.component('search-bar', SearchBar);
Vue.component('search-bar-simple', SearchBarSimple);
Vue.component('accotation', Accotation);
Vue.component('accotation-without-doughnut', AccotationWithoutDoughnut);
Vue.component('indicator-subtitle', IndicatorWithSubtitle)
Vue.component('indicator-subtitle-date', IndicatorWithSubtitleDate)
Vue.component('indicator-subtitle-h2', IndicatorWithSubtitleH2)
Vue.component('indicator-subtitle-h3', IndicatorWithSubtitleH3)
Vue.component('indicator-subtitle-min-max', IndicatorWithSubtitleMinMax)
Vue.component('indicator-subtitle-delta', IndicatorWithSubtitleAndDelta)
Vue.component('indicator-title', IndicatorWithTitle)
Vue.component('sup-breadcrumb', SupBreadcrumb)
Vue.component('table-indicators', TableIndicators)
Vue.component('date-filter', DateFilter);
Vue.component('from-date-filter', FromDateFilter);
Vue.component('to-date-filter', ToDateFilter);
Vue.component('date-filter-7-days', DateFilter7Days); 
Vue.component('card-full-width', CardFullWidth); 
Vue.component('card-half-width', CardHalfWidth); 
Vue.component('popover', PopOver); 


Vue.component('mastersidenav-template', MasterSideNav);
Vue.component('appside-template', AppSideNavTemplate);
Vue.component('app-template', AppTemplate);
  
Vue.component('title-component', Title);
Vue.component('comparison-filter', ComparisonFilter);
Vue.component('comparison-filter-no-caption', ComparisonFilterNoCaption);
Vue.component('b-form', bForm)
Vue.component('b-input-group', bInputGroup)
Vue.component('b-form-group', bFormGroup);
Vue.component('b-form-input', bFormInput);
Vue.component('b-form-checkbox-group', bFormCheckboxGroup);
Vue.component('b-form-checkbox', bFormCheckbox); 
Vue.component('custom-vs-sidebar-group', CustomVSSidebarGroup);
Vue.component('v-select', vSelect);
Vue.component('download-excel', JsonExcel);
Vue.component('download-data-button', DownloadDataButton); 

 

new Vue({
  router,
  store,
  render: h => h(App),

  data: {
    isSidebarActive: false
  },
  mounted(){
	  
  }
}).$mount('#app')