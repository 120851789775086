<template>
    <div class="comparison-filter-no-caption">
        <vs-row>
            <vs-col vs-tooltip="'col - 12'" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12" vs-sm="12" vs-xs="12">
                <select class="form-control select-acotation" @change="changeComparison" label="Figuras" v-model="select1">
                    <option :key="index" :value=item.value :vs-text="item.text" v-for="(item,index) in items">{{item.text}}</option>>
                </select>
                <!-- <vs-select @change="changeComparison" class="selectExample" label="Figuras" v-model="select1">
                    <vs-select-item :key="index" :vs-value="item.value" :vs-text="item.text" v-for="(item,index) in items" />
                </vs-select> -->
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
export default {
    props:['items', 'selected', 'caption', 'name'],
    data() {
        return {
            select1: this.selected,
            isMobile: window.innerWidth <= 835,
        }
    },
    methods: {
        changeComparison(){
            let varName = "";

            if(this.name !== undefined){
                varName = 'set' + this.name;
            } else {
                varName = "default"
            }
            
            this.$store.commit(varName, this.select1);

            this.$root.$emit('changeItem');
        }
    },
    mounted(){
        this.$nextTick(() => {
            window.addEventListener('resize', () => {
                if(window.innerWidth < 835) {
                    this.isMobile = true;
                } else {
                    this.isMobile = false;
                }
            });
        })
    }
}
</script>

