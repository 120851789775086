var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('resize-observer',{attrs:{"breakpoints":_vm.breakpoints},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var el = ref.el;
return _c('sb-pagination',{staticClass:"pagination-wrapper",class:el.is,attrs:{"pageSize":_vm.pageSize,"currentPage":_vm.currentPage,"totalPages":_vm.totalPages,"totalRecords":_vm.totalRecords,"visibleRecords":_vm.visibleRecords},on:{"fetchPage":_vm.handleFetchPage},scopedSlots:_vm._u([{key:"pagination-text",fn:function(ref){
var currentPage = ref.currentPage;
var totalPages = ref.totalPages;
var totalRecords = ref.totalRecords;
var visibleRecords = ref.visibleRecords;
return _c('sb-pagination-text',{},[_vm._v("\n      "+_vm._s(_vm.constructPaginationText(el, currentPage, totalPages, totalRecords, visibleRecords))+"\n    ")])}}])},[_c('sb-pagination-links',{attrs:{"slot":"pagination-links","range":_vm.calculateRange(el)},slot:"pagination-links",scopedSlots:_vm._u([{key:"page-links",fn:function(ref){
var page = ref.page;
return _c('sb-pagination-link',{},[_vm._v(_vm._s(page))])}}])},[_c('sb-pagination-link',{attrs:{"slot":"page-link-previous"},slot:"page-link-previous"},[_vm._v("Previous")]),_c('sb-pagination-link',{attrs:{"slot":"page-link-next"},slot:"page-link-next"},[_vm._v("Next")])],1),_c('sb-pagination-input',{attrs:{"slot":"pagination-input","labelText":_vm.constructGoToText(el)},slot:"pagination-input"})],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }