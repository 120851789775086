export default [  
  {
    id:1,
    name: 'Dashboard',
    href: '/dashboard',
    icon: 'dashboard', 
    callback: function () {
      this.$router.push({ name: 'Dashboard' });
    },
    show: true
}   
];
