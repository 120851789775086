<template>
  <div class="container-fluid flex-full-height">
    <div class="flex-1 d-flex">
      <div id="hero-image-wrapper" class="row">
        <div class="col-12 flex-full-height px-75 py-50 setup-guide-hero">
          <div class="flex-full-height">
            <div class="flex-1 d-flex flex-column">
              <div class="row">
                <h1 class="hero-header bold font-weight-bold line-height-56">
                  <br />
                </h1>
                <h2 class="hero-header-2"></h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="setup-guide-login-wrapper" class="row">
        <div
          class="
            form-container
            col-12
            flex-full-height
            justify-content-end
            px-20 px-l-40 px-xxl-100
            py-30
          "
        >
          <div class="row flex-1 flex-column justify-content-center">
            <vs-row>
              <vs-col vs-justify="center" vs-w="12" vs-align="center">
                <div
                  v-if="this.loading"
                  class="spin-center-ideal-distribution center"
                >
                  <div class="ld ld-ring ld-spin running"></div>
                </div>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-justify="space-around" vs-w="12" vs-align="center">
                <h4 class="subtitle">Contacting Shopify..</h4>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import toastr from "toastr";
import axiosInstance from "../helpers/ws";
import store from "../store";
import AuthTokenUtil from "@/utils/http/authToken";

export default {
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.installRoute();
  },
  computed: {},
  methods: {
    installRoute() {
      if (
        this.$route.query.shop != undefined &&
        this.$route.query.shop != undefined &&
        this.$route.query.shop != "" &&
        this.$route.query.hmac != undefined &&
        this.$route.query.shop.toLowerCase().includes("myshopify")
      ) {
        if (this.$route.path.toLowerCase() == "/auth/shopify") {
          if (this.$route.query.shop != undefined) {
            this.install(this.$route.query.shop.toLowerCase());
          }
        } else if (
          this.$route.path.toLowerCase() == "/install/shopifycallback"
        ) {
          if (this.$route.query.state !== undefined) {
            const state = this.getStateObject(this.$route.query.state)
            if (state && state.scope_update) {
              this.updateScope(this.$route.query.shop.toLowerCase(), state.account_id)
              return;
            }
          }
          this.loadAuthentication();
        }
        return;
      } else {
        this.$router.push("/");
      }
    },
    install(shop) {
      if (shop == undefined || shop == "") {
        toastr.error("Enter a valid store name");
        return;
      }

      shop = shop.replace(".myshopify.com", "");
      this.persistShop(shop);

      axiosInstance(
        "GET",
        process.env.VUE_APP_SHOPIFY_ENDPOINT +
          "install?" +
          Object.keys(this.$route.query)
            .map((key) => key + "=" + this.$route.query[key])
            .join("&"),
        null
      ).catch(function (error) {
          Vue.$toast.open({
            message: " Error! " + error.response.data.error,
            type: "error",
            position: "top-right",
            duration: 10000,
          });
        })
        .then((response) => {
          if (response.data.storeInstalled) {
            this.loginLink = response.data.reponseURL;
            this.removeShop(); //no need to keep it if already installed
            window.location.replace(this.loginLink); //should be going to web app
          } else {
            this.loginLink = response.data.reponseURL;
            window.location.replace(
              this.loginLink + "&state=" + this.persisteNonce()
            );
          }
        });
    },
    updateScope(shop, account_id) {
      if (!shop.endsWith("myshopify.com")) {
        return;
      }

      this.loading = true;

      var request = {
        code: this.$route.query.code,
        myShopifyUrl: shop,
        cartBobAccountId: account_id,
      };

      axiosInstance(
        "POST",
        process.env.VUE_APP_SHOPIFY_ENDPOINT +
          "install/update_scope?" +
          Object.keys(this.$route.query)
            .map((key) => key + "=" + this.$route.query[key])
            .join("&"),
        null,
        request
      )
        .catch(function (error) {
          Vue.$toast.open({
            message: " Error! " + error.response.data.error,
            type: "error",
            position: "top-right",
            duration: 10000,
          });
        })
        .then((response) => {
          if (response.status === 200) {
            const merchantAppBaseUrl = `${process.env.VUE_APP_SHIPBOB_SITE}/app/merchant/#`;
            const redirectUrl = `${merchantAppBaseUrl}/2DayShippingBadges/Edit/${response.data}`;
            window.location.href = redirectUrl;
          } else {
            const baseMessage = "Error occurred!";
            let errorMessage =
              typeof res.data === "object"
                ? res.statusText
                : res.data || res.response
                ? res.response.data
                : "";
            if (res.response.data.error != undefined)
              errorMessage = res.response.data.error;
            if (
              errorMessage === undefined ||
              errorMessage.length === 0 ||
              typeof errorMessage === "object"
            )
              errorMessage = baseMessage;
            Vue.$toast.open({
              message: errorMessage,
              type: "error",
              position: "top-right",
              duration: 10000,
            });
          }
        });
    },
    persisteNonce() {
      var nonce = Math.floor(Math.random() * 100000000 + 1);
      window.localStorage.setItem("shop_nonce", nonce);
      return nonce;
    },
    extractShopNonceFromLocal() {
      return window.localStorage.getItem("shop_nonce");
    },
    removeShopNonce() {
      window.localStorage.removeItem("shop_nonce");
    },
    persistShop(shopName) {
      if (shopName == undefined) {
        return;
      }
      window.localStorage.setItem("shop", shopName);
    },
    extractShopFromLocal() {
      return window.localStorage.getItem("shop");
    },
    removeShop() {
      window.localStorage.removeItem("shop");
    },
    persistInstall(install) {
      if (install == undefined) {
        return;
      }
      window.localStorage.setItem("install", JSON.stringify(install));
    },
    extractInstallFromLocal() {
      try {
        return JSON.parse(window.localStorage.getItem("install")) || {};
      } catch (e) {
        window.localStorage.removeItem("install");
        return {};
      }
    },
    removeInstall() {
      window.localStorage.removeItem("install");
    },
    async loadAuthentication() {
      var nonce = this.extractShopNonceFromLocal();

      if (this.$route.query.state != nonce) {
        return;
      }
      if (!this.$route.query.shop.endsWith("myshopify.com")) {
        return;
      }
      this.loading = true;
      let auth = AuthTokenUtil.extractAuth();
      var request = {
        code: this.$route.query.code,
        myShopifyUrl: this.$route.query.shop,
        cartBobAccountId: null,
      };

      axiosInstance(
        "POST",
        process.env.VUE_APP_SHOPIFY_ENDPOINT +
          "install/shopifycallback?" +
          Object.keys(this.$route.query)
            .map((key) => key + "=" + this.$route.query[key])
            .join("&"),
        null,
        request
      )
        .catch(function (error) {
          Vue.$toast.open({
            message: " Error! " + error.response.data.error,
            type: "error",
            position: "top-right",
            duration: 10000,
          });
        })
        .then((response) => {
          if (response.status === 200) {
            this.persistInstall(response.data);
            this.fetchLoginLink();
          } else {
            const baseMessage = "Error occurred!";
            let errorMessage =
              typeof res.data === "object"
                ? res.statusText
                : res.data || res.response
                ? res.response.data
                : "";
            if (res.response.data.error != undefined)
              errorMessage = res.response.data.error;
            if (
              errorMessage === undefined ||
              errorMessage.length === 0 ||
              typeof errorMessage === "object"
            )
              errorMessage = baseMessage;
            Vue.$toast.open({
              message: errorMessage,
              type: "error",
              position: "top-right",
              duration: 10000,
            });
          }
          this.removeShopNonce();
        });
    },
    fetchLoginLink() {
      axiosInstance(
        "GET",
        process.env.VUE_APP_ENDPOINT + "auth?flow=1",
        null
      ).then((response) => {
        window.location = response.data;
      });
    },
    getStateObject(stateParam) {
      try {
        const stateStr = atob(stateParam);
        return JSON.parse(stateStr);
      } catch (ex) {
        return undefined;
      }
    }
  },
};
</script>
<style scoped lang='scss'>
</style>