<template>
<div id="appContainer">
    <admin-bar v-if="isAdmin" :offset.sync="offset"></admin-bar>
    <fade-transition :mode="'out-in'">
      <slot></slot>
    </fade-transition>
    <sb-splash-screen id="splashScreenStyle"></sb-splash-screen>
</div>
</template>
<script>
import SbSplashScreen from '@/components/utils/SbSplashScreen'
import MasterSideNav from '@/components/layouts/MasterSideNav'
import AdminBar from '@/components/layouts/AdminBar'
import AppTemplateMixin from './AppTemplateMixin'
export default {
  name: 'AppTemplate',
  components: { SbSplashScreen, MasterSideNav, AdminBar },
  mixins: [AppTemplateMixin]
}
</script>
<style lang="scss">
  #splashScreenStyle {
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index:9999;
    width: 100%;
    height: 100%;
  }
</style>
