import UserValidationModel from './userValidation'
 
import {ObjectWithDefault} from '../../utils/objectWithDefault'

export default class UserModel extends UserValidationModel {
  constructor (u) {
    super()
    const defaultUser = {
      UserId: {val: 0, type: Number},
      ImpersonatedUserId: {val: 0, type: Number},
      UserRoleId: {val: 0, type: Number},
      CityID: {val: 0, type: Number},
      FirstName: {val: '', type: String},
      LastName: {val: '', type: String},
      Email: {val: '', type: String},
      CompanyName: {val: '', type: String},
      CompanyWebsite: {val: '', type: String},
      WorkingWithSales: {val: '', type: Boolean},
      password: {val: '', type: String},
      confirmPassword: {val: '', type: String},
      verifiedRecaptcha: {val: false, type: Boolean},
      employeeSignupToken: {val: 0, type: Number},
      preLaunchUser: {val: false, type: Boolean},
      LastCompletedStep: {val: 8, type: Number},
      IsAdmin: {val: false, type: Boolean},
      ImpersonatedEmail: {val: '', type: String}
    }
    const user = ObjectWithDefault(defaultUser, u)
    this.id = user.UserId
    this.impersonatedUserId = user.ImpersonatedUserId
    this.role = user.UserRoleId
    this.cityId = user.CityID
    this._firstName = user.FirstName
    this._lastName = user.LastName
    this._email = user.Email
    this._companyName = user.CompanyName
    this._companyWebsite = user.CompanyWebsite
    this._workingWithSales = user.WorkingWithSales
    this._password = user.password
    this._confirmPassword = user.confirmPassword
    this.verifiedRecaptcha = user.verifiedRecaptcha
    this.preLaunchUser = user.preLaunchUser
    this.employeeSignupToken = user.employeeSignupToken
    this.lastCompletedStep = user.LastCompletedStep
    this.isAdmin = user.IsAdmin
    this.impersonatedEmail = user.ImpersonatedEmail
  }

  get firstName () {
    return this.mapAttributeGetter('firstName')
  }
  set firstName (val) {
    this.mapAttributeSetter('firstName', val)
  }

  get lastName () {
    return this.mapAttributeGetter('lastName')
  }
  set lastName (val) {
    this.mapAttributeSetter('lastName', val)
  }

  get companyName () {
    return this.mapAttributeGetter('companyName')
  }
  set companyName (val) {
    this.mapAttributeSetter('companyName', val)
  }

  get companyWebsite () {
    return this.mapAttributeGetter('companyWebsite')
  }
  set companyWebsite (val) {
    this.mapAttributeSetter('companyWebsite', val)
  }

  get workingWithSales () {
    return this.mapAttributeGetter('workingWithSales')
  }
  set workingWithSales (val) {
    this.mapAttributeSetter('workingWithSales', val)
  }

  get email () {
    return this.mapAttributeGetter('email')
  }
  set email (val) {
    this.mapAttributeSetter('email', val)
  }

  get password () {
    return this.mapAttributeGetter('password')
  }
  set password (val) {
    this.mapAttributeSetter('password', val)
  }

  get confirmPassword () {
    return this.mapAttributeGetter('confirmPassword', {matchValue: this._password})
  }
  set confirmPassword (val) {
    this.mapAttributeSetter('confirmPassword', val)
  }

  isDefault () {
    return this.id === 0
  }

  mapForAnalytics () {
    return {
      id: this.id,
      email: this._email,
      firstName: this._firstName,
      lastName: this._lastName
    }
  }

  mapEmailForForgotPassword () {
    return {
      email: this._email
    }
  }

  mapForLogin () {
    return {
      email: this._email,
      password: this._password
    }
  }

  mapForAdminPage () {
    return {
      impersonatedEmail: this.impersonatedEmail,
      impersonatedUserId: this.impersonatedUserId
    }
  }
}
