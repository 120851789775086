<template>
    <div style="margin-top:auto;">
        <vs-row vs-w="12" vs-justify="center"  >
            <vs-col vs-w="12" vs-justify="flex-start">
                <div v-if="tooltip === undefined">
                    <h2 class="mb-0" style="text-align:center" :class="colorClass"><span v-if="signo !== 'undefined'">{{signo}}</span>{{(value==0)||(value==undefined)?"-":numberWithCommas(value)}}{{simbol}}</h2>
                     <div v-if="subtitleLink === undefined"><p :style="'text-align:center; font-size:' + (subtitleFontSize==undefined?'medium':(subtitleFontSize))">{{subtitle}}</p></div>
                        <div v-else><p :style="'text-align:center; font-size:' + (subtitleFontSize==undefined?'medium':(subtitleFontSize))">
                             <a :href="subtitleLink" class="link-more-details">{{subtitle}}</a>
                           </p></div>
                </div>
                <div v-else>
                    <vs-tooltip :text="tooltip" position="top" >
                        <h2 class="mb-0" style="text-align:center" :class="colorClass"><span v-if="signo !== 'undefined'">{{signo}}</span>{{(value==0)||(value==undefined)?"-":numberWithCommas(value)}}{{simbol}}</h2>
                       <div v-if="subtitleLink === undefined"><p :style="'text-align:center; font-size:' + (subtitleFontSize==undefined?'medium':(subtitleFontSize))">{{subtitle}}</p></div>
                        <div v-else><p :style="'text-align:center; font-size:' + (subtitleFontSize==undefined?'medium':(subtitleFontSize))">
                             <a :href="subtitleLink" class="link-more-details">{{subtitle}}</a>
                           </p></div>
                    </vs-tooltip>
                </div> 
            </vs-col>            
        </vs-row>
    </div>
</template>
<script>
export default {
    props:['value', 'subtitle', 'colorClass', 'delta', 'simbol', 'tooltip', 'signo', 'subtitleLink','subtitleFontSize'],
    methods: {
        numberWithCommas(x) { 
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
}
</script>