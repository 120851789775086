<template>
  <div class="dropdown">
    <button type="button"  v-on:click="toggleDropDown" :class="controlClass" data-toggle="dropdown" v-click-outside="onCloseEvent">{{ titleText }}</button>
    <span v-bind:class="{'the-one-true-carrot': showOptions, 'disabled-carrot': nonAdminOptions[0].isDisabled && nonAdminOptions[0].isDisabled()}"></span>
      <div class="dropdown-menu" v-bind:class="{show: showOptions, 'dropdown-menu-right': rightAlign}" id="t1">
        <a class="dropdown-item" v-bind:class="{textAlign: true, 'disabled': nao.isDisabled && nao.isDisabled()}" v-on:click="myOnClick(nao.Callback, nao.Href, nao.isDisabled)" v-for="nao in nonAdminOptions" :key="nao.Id">{{ nao.Title }}</a>
        <div class="dropdown-admin" v-show="adminOptions">
          <div class="dropdown-admin-header"> Admin Only </div>
          <a class="dropdown-item dropdown-admin-item" 
            v-bind:class="{textAlign: true, 'disabled': ao.isDisabled && ao.isDisabled()}"
            @click="myOnClick(ao.Callback, ao.Href, ao.isDisabled)" 
            v-for="ao in adminOptions" 
            :key="ao.Id">ao.Title
          </a>
        </div>
      </div>
  </div>
</template>
<script>
import messages from './UtilsTexts.js'
export default {
  name: 'PopOver',
  props: [
    'nonAdminOptions',
    'adminOptions',
    'controlClass',
    'titleText',
    'textAlign',
    'modalNum',
    'rightAlign'
  ],
  data () {
    return {
      showOptions: false,
      closeEvent: 'onCloseEvent'
    }
  },
  methods: {
    toggleDropDown: function () {
      this.showOptions = !this.showOptions
    },
    routeTo: function (href) {
      this.$router.push({ path: href })
    },
    onCloseEvent: function () {
      if (this.showOptions) this.toggleDropDown()
    },
    callback: function (cb, e) {
      if (!cb) {
        return
      } else if (cb.constructor.name === 'Function') {
        cb(e)
      }
    },
    myOnClick: function (cb, h, disable = () => false) {
      if (!disable()) {
        if (!cb) {
          if (h) {
            window.location.href = h
          }
        } else {
          cb()
        }
      }
    }
  },
  i18n: { messages }
}
</script>
<style lang="scss" scoped>
 @import '../../../assets/scss/shipbob-custom/bootstrap-overrides/_dropdown_v2.scss';
.dropdown-menu-right {
  right: 0;
  left: auto;
}

.disabled-carrot {
    border-bottom: 6px solid #f2f3f6 !important;
  }
.the-one-true-carrot {
    position: absolute;
    top: 30px;
    right: 16px;
    content: " ";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    z-index: 100000;
  }
</style>
