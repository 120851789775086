import InstaFocus from '@/directives/insta-focus'
import Focus from '@/directives/focus'
import ClickOutside from '@/directives/click-outside'
import TrackClick from '@/directives/track-click'
import Expand from '@/directives/expand'

export default {
  install (Vue, options) {
    Vue.directive(InstaFocus.name, InstaFocus.directive)
    Vue.directive(Focus.name, Focus.directive)
    Vue.directive(ClickOutside.name, ClickOutside.directive)
    Vue.directive(TrackClick.name, TrackClick.directive)
    Vue.directive(Expand.name, Expand.directive)
  }
}
