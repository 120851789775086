<template>
  <div class="container-fluid flex-full-height">
    <div class="flex-1 d-flex">
      <div id="hero-image-wrapper" class="row">
        <div class="col-12 flex-full-height px-75 py-50 setup-guide-hero">
          <div class="flex-full-height">
            <div class="flex-1 d-flex flex-column">
              <div class="row">
                <h1 class="hero-header bold font-weight-bold line-height-56">
                   
                  <br /> 
                </h1>
                <h2
                  class="hero-header-2"
                > </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="setup-guide-login-wrapper" class="row">
        <div
          class="form-container col-12 flex-full-height justify-content-end px-20 px-l-40 px-xxl-100 py-30"
        >
          <div class="row flex-1 flex-column justify-content-center">
             
           
            
            <vs-row  >
              <vs-col vs-justify="center" vs-w="12"   vs-align="center">
               <div v-if="this.loading" class="spin-center-ideal-distribution center">
            <div class="ld ld-ring ld-spin running"></div>
        </div>
        </vs-col>
           </vs-row>
            <vs-row  >
              <vs-col vs-justify="space-around" vs-w="12"   vs-align="center">
                           <h4 class="subtitle">Contacting ShipBob..</h4>

        </vs-col>
           </vs-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import toastr from "toastr";
import axiosInstance from "../helpers/ws";
import store from '../store';
import AuthTokenUtil from '@/utils/http/authToken'

export default {
  data() {
    return {
       loading:true
    };
  },
  mounted() {     
     this.loadAuthentication(); 
  },
  computed: {
    
     
  },
  methods: {
     getHash(hash)
     {
       hash = hash.substring(1);
       var result = hash.split('&').reduce(function (result, item) {
            var parts = item.split('=');
            result[parts[0]] = parts[1];            
            return result;
        }, {});
        return result;
     },
    async loadAuthentication()
     {
       this.loading=true;
          var result = this.getHash(this.$route.hash);          
          var codes = {
            code : result.code,
            id_token : result.id_token
          };
         var result =await this.authenticateCode(codes);  
     },
      authenticateCode: async function(code) {
          await new Promise(res => { 
          var result = store.dispatch('authenticateCode',code); 
           return result;
        });
      }
  }
};
</script>
<style scoped lang='scss'> 
</style>