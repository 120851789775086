<template>
  <div class="sidebar-footer">
     <div>
        <mq-layout :mq="['xs','sm','xl', 'xxl']">
        <nav class="navbar side-bar-account-footer">
            <a class="logout" v-on:click='logOut()'></a> 
                <!-- <img :src="logo"> -->
                <!-- <router-link
                :to="{ name: 'Account', params: {} }"
              class="your-account" >Your Account</router-link>  -->
        </nav>
        </mq-layout>
        <mq-layout :mq="['md', 'lg']">
        <nav class="navbar side-bar-account-footer account-footer-md">
            <a class="logout logout-md" v-on:click='logOut()'></a> 
             <!-- <router-link
                :to="{ name: 'Account', params: {} }"
              class="your-account your-account-md" >Your Account</router-link>  -->
        </nav>
        </mq-layout>
    </div>
      
  </div>
</template>

<script> 
import SideNavLink from './SideNavLink'
export default {
  name: 'SideNavFooter',
  components: { SideNavLink },
  props: {
    showQuickSetup: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      link:{ 
        name: 'Your Account',
        href: '/account',
        icon: 'calc', 
        callback: function () {
         // this.$router.push({ name: 'Account' })
        },
        show: true
      } 
    }
  },
  mounted()
  {
    if(this.$store.state.noAccessObject != undefined)
            {
                if (this.$store.state.noAccessObject.find(x => x === 2)){//this is for wms people only.
                    this.$router.push({ name: 'Dashboard' });
                }
            } 
        this.$root.$on('NoAccessObject', () => {  
           if(this.$store.state.noAccessObject != undefined)
            {
                if (this.$store.state.noAccessObject.find(x => x === 2)){//this is for wms people only.
                    this.$router.push({ name: 'Dashboard' });
                }
            }           
         }); 

  },
  destroyed(){
       this.$root.$off('NoAccessObject'); 
  },
  methods: {
    async logOut () { 
      const success = await this.$store.dispatch('user/logout', null)
      if (success) {
        this.$router.push({ name: 'Login' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar.side-bar-brand-header{
  height: inherit;
  font-weight: bold;
}
</style>

