export const DESCENDING = 'desc'
export const ASCENDING = 'asc'
const sortDelimiter = ' '
const filterDelimiter = sortDelimiter
const displayFilterDelimiter = ':';
export const PAGINTAION_SERVERSIDE = "SERVERSIDE"
export const PAGINTAION_CLIENTSIDE = "CLIENTSIDE" 
import moment from "moment";
export default class TableHelper  { 
     
    constructor (itemmapper,requestedPageSize,paginationType) { 
        this.mapper = itemmapper; 
        this.receivedData = [];
        this.pagedData =  [];
        this.pageSize = requestedPageSize;
        this.pagination = {
                pageSize:requestedPageSize,
                currentPage:1,
                totalPages:1,
                totalRecords:0,
                visibleRecords:30,
                paginationType:paginationType==undefined?PAGINTAION_CLIENTSIDE:paginationType
            };
      } 

        handleSort ({ sortColumn, descending }) {
            //descending is either true or false      
           const sortFieldName = sortColumn.field;
           var sortOrder = descending ? "-" : ""; 
           this.receivedData.sort(this.internalSort(sortOrder+sortFieldName)); 
           this.loadData(this.receivedData, this.pagination.pageNumber);
         } 

         internalSort(property) {
            var sortOrder = 1;
            if(property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a,b) {
                /* next line works with strings and numbers, 
                * and you may want to customize it to your needs
                */
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        } 

        preLoadReset()
        {
            this.receivedData = [];
            this.pagedData = [];
        }

    async loadData(data, paginationData, pageNumber)
    {   
        await new Promise(res => {
     
            this.receivedData=data;  
            this.pagedData = [];
            if (pageNumber==undefined){pageNumber=1;}
            this.pagination.currentPage = pageNumber; 
            if(this.pagination.paginationType==PAGINTAION_CLIENTSIDE)
            {
                var page = this.paginate(this.receivedData,this.pageSize,pageNumber);  
                this.pagination.totalPages =Math.ceil(this.receivedData.length/this.pageSize);
                this.pagination.totalRecords =this.receivedData.length; 
                this.pagination.visibleRecords = page.length; 
                page.forEach((item) => { 
                    this.pagedData.push(
                        this.mapper.map(item) 
                );
                }); 
            }else{ 
                this.pagination.totalPages = paginationData.pageCount; 
                this.pagination.totalRecords = paginationData.recordCount;
                this.pagination.visibleRecords = data.length; 
                data.forEach((item) => { 
                    this.pagedData.push(
                        this.mapper.map(item) 
                );
                }); 
            }
            res(true); 
      });
    } 
   static dateFormat(date) {
        if(date==null||undefined){return "";}
        return moment(new Date(date)).format('MM/DD/YYYY');
    }
    static dateMoment(date) {
        if(date==null||undefined){return "";}
        return moment(new Date(date));
    }
    static dateFormatWithTime(date) {
        if(date==null||undefined){return "";} 
        return moment(new Date(date)).format("MM/DD/YYYY h:mm a");
    } 
   static numberWithCommas(x,decimalCount) {
       if(decimalCount==undefined){ decimalCount=2;}
        return x==undefined?x: x.toFixed(decimalCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } 
    paginate (array, page_size, page_number) {
      --page_number; // because pages logically start with 1, but technically with 0 
      return array.slice(page_number * page_size, (page_number + 1) * page_size);
    }
    
      static getIntegerDisplay (value) {        
        return value ? value.toFixed(0) : '0';
      }
      static  getDaysDisplay (value) {        
        return value ? value.toFixed(1) : '0.0';
      }
      static  getMoneyDisplay (value,symbol) {
        if (symbol == undefined){symbol='$';} 
        return value ? symbol + value.toFixed(2) : symbol +'0.00';
      }
    static roundToTwo(num) {    
        return +(Math.round(num + "e+2")  + "e-2");
    }
       
}  