import BaseHeader from '@/components/layouts/BaseHeader'
import BaseFooter from '@/components/layouts/BaseFooter'

export default {
  install (Vue, options) {
    
    Vue.component(BaseHeader.name, BaseHeader)
    Vue.component(BaseFooter.name, BaseFooter)
    
  }
}
