<template>
    <div>
        <vs-row vs-w="12" vs-justify="center" >
            <vs-col vs-w="12" vs-justify="flex-start">
                <h2 class="mb-0" style="text-align:center" :class="colorClass">{{value}}</h2>
                <p style="text-align:center">{{subtitle}}</p>
            </vs-col>            
        </vs-row>
    </div>
</template>
<script>
export default {
    props:['value', 'subtitle', 'colorClass', 'delta'],
}
</script>

