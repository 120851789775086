import toastr from 'toastr'
 
const defer = (cb) => {
  setTimeout(cb, 0)
}

class InternalPendoWrapper {
  constructor () {
    this.initRun = false
  }
  async init (user) {
    if (this.initRun) console.warn('[PendoWrapper] init has already been performed.')
    else {
      this.initRun = true 
      defer(async () => {
        // await import(/* webpackChunkName: "Pendo", webpackPrefetch: true */ '../vendor/pendo')
        // this.initializePendo(user)
      })
    }
  }

  initializePendo (user) {
    window.pendo.initialize({
      visitor: {
        id: user.Email,
        email: user.Email,
        role: user.UserRole,
        company_name: user.CompanyName,
        company_website: user.CompanyWebsite,
        insert_date: user.InsertDate,
        user_id: user.UserId,
        is_admin: user.IsAdmin,
        first_name: user.FirstName,
        last_name: user.LastName,
        domestic_pricing_plan_id: user.DomesticPricingPlanId,
        international_pricing_plan_id: user.InternationalPricingPlanId,
        account_manager_id: user.AccountManager,
        support_manager_id: user.CustomerServiceManager,
        user_role_id: user.UserRole,
        store_intergration_names: user.StoreIntegrationNames,
        number_of_store_intergrations: user.NumberOfIntegrations,
        user_growth_experimnet_Id: user.UserGrowthExperimentId,
        when_ready_to_ship: user.WhenReadyToShip,
        go_live_status: user.IsLive,
        number_recieved_Wro: user.NumberOfRecievedWRO,
        number_items_on_hand: user.NumberOfItemsOnHand,
        has_credit_card_on_file: user.CreditCardOnFile,
        shiping_options_set: user.ShipOptionsSet,
        no_tariff_codes_added: user.TariffCodesAdded,
        has_lot: user.HasLot,
        has_serial_numbers: user.HasSerialNumbers,
        active_rules: user.ActiveRules,
        has_uro: user.HasURO,
        two_day_enabled: user.TwoDayEnabled,
        one_day_enabled: user.OneDayEnabled,
        carrier_day_enable: user.CarrierServicesEnabled
      },
      account: {
        id: user.CompanyName,
        name: `${user.FirstName} ${user.LastName}`,
        company_name: user.CompanyName,
        domestic_pricing_plan_id: user.DomesticPricingPlanId,
        international_pricing_plan_id: user.InternationalPricingPlanId,
        account_manager_id: user.AccountManager,
        support_manager_id: user.CustomerServiceManager,
        user_role_id: user.UserRole,
        store_intergration_names: user.StoreIntegrationNames,
        number_of_store_intergrations: user.NumberOfIntegrations,
        go_live_status: user.IsLive,
        number_recieved_Wro: user.NumberOfRecievedWRO,
        has_lot: user.HasLot,
        has_serial_numbers: user.HasSerialNumbers,
        active_rules: user.ActiveRules,
        has_uro: user.HasURO,
        two_day_enabled: user.TwoDayEnabled,
        one_day_enabled: user.OneDayEnabled,
        carrier_day_enable: user.CarrierServicesEnabled
      },
      guides:{
        disable:true
      }
    }); 
  }
}

export const PendoWrapper = new InternalPendoWrapper()
