import Vue from 'vue'
import Vuex from 'vuex'
import { user } from '@/stores/user'
import AuthorizationService from '@/services/authorization'
import UserService from '@/services/user' 

Vue.use(Vuex)

let date = new Date();

export default new Vuex.Store({
  state: { 
    env:'development',   
    isAdmin: null,
    authenticated: false,
    impersonatedUser:{},
    loggedInUser:{}
  },
  mutations: {
      
    isAdmin: (state, {IsAdmin}) => {
      state.isAdmin = IsAdmin
    },
    setAuthentication (state, auth) {
      state.authenticated = auth
    },
    resetAuthentication (state) {
      state.authenticated = false
    },
    setImpersonatedUser (state, value) {
      state.impersonatedUser = value; 
    },
    setLoggedInUser (state, value) {
      state.loggedInUser = value; 
    }
  },
  actions: {
    update({commit}) {
      console.log("Update ready !!!")
    },
    isAdmin: async (context) => {
      if (context.state.isAdmin !== null) return
      let request = {
        Url: process.env.Shipbob_Site +  '/api/admin/isAdmin'
      }
      let res = await HttpUtil.Get(request)
      context.commit('isAdmin', {IsAdmin: res.data})
    }, 
    async authenticate (context) {
      var authorized = await AuthorizationService.authenticate()
      if (!authorized) {
        authorized = await AuthorizationService.refreshToken()
      }
      context.commit('setAuthentication', authorized)
      return authorized
    },
    async authenticateCode (context,code) {
      var authorized = await AuthorizationService.authenticateCode(code) 
      context.commit('setAuthentication', authorized)
      return authorized
    },
    resetAuthentication (context) {
      context.commit('resetAuthentication')
    },
    async fetchDetailedUser () {
      return await UserService.getDetailedUser()
    }
  },
  modules: {
    user: user
  },
  getters : {
    isAdmin: state => state.isAdmin,
    impersonatedUser: state => state.impersonatedUser,
    loggedInUser: state => state.loggedInUser 
     
  } 
})
