<template>
  <div class="sidebar-items">
    <nav class="nav flex-column nav-pills primary-nav" id="primary-nav">
      <div class="custom-scroll">
        <SideNavLink v-for="(link, index) in navLinks" :key="index" :link="link"></SideNavLink> 
      </div>
    </nav>
  </div>
</template>

<script>
import SideNavLink from './SideNavLink'
export default {
  name: 'SideNavItems',
  components: {SideNavLink},
  props: {
    sideNavType: {
      type: String,
      required: true
    },
    showQuickSetup: {
      type: Boolean,
      default: true
    },
    navLinks: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      links: []
    }
  }
}
</script>

<style lang="scss" scoped>

  .sidebar-middleman{
    flex-grow: 2;
    display: flex;
    flex-direction: column;
  }

</style>

