<template>
          <sb-button @click="download()" size="small" variation="tertiary">  <i class="fas far fa-cloud-download-alt"></i> Download</sb-button> 
</template>
<script> 
import exportCSV from "@/utils/exportCSV";
export default {
  props: [ 
    "dataName",
    "data",
    "downloadAllPages"
  ],
  data() {
    return {
      receivedData: []
    };
  },
  mounted() {
    if (this.downloadAllPages == undefined) { 
      this.$root.$on("finishedFetch", () => { 
            if (this.dataName !== undefined) {  
              this.receivedData = this.$store.state[`${this.dataName}`];
            }else if (this.data !== undefined) { 
              this.receivedData = this.data; 
            } 
        });
    }
     
    
  },
  destroyed(){
    this.$root.$off("finishedFetch");
  },
  methods: {
    download: function() {
      if (this.downloadAllPages !== undefined) {
        this.$root.$emit("finishedFetchAllDownload"); 
      }else 
      { 
        exportCSV.export("tableData",this.receivedData);
      }
    }  
  }
};
</script> 