import toastr from 'toastr'

import { mapLoggedInAndImpersonatedUserFromApi } from '@/mappers/user'

import UserService from '@/services/user'
import UserModel from '@/models/user/user'

export const user = {
  namespaced: true,
  state: {
    user: new UserModel(),
    quickSetupSteps: [],
    landingPageEmailGuid: null
  },
  mutations: {
    setAgreedToTerms (state, val) {
      state.user.agreedToTerms = val
    },
    setCompanyName (state, val) {
      state.user.companyName = val
    },
    setCompanyWebsite (state, val) {
      state.user.companyWebsite = val
    },
    setWorkingWithSales (state, val) {
      state.user.workingWithSales = val
    },
    setFirstName (state, val) {
      state.user.firstName = val
    },
    setLastName (state, val) {
      state.user.lastName = val
    },
    setEmail (state, val) {
      state.user.email = val
    },
    setPassword (state, val) {
      state.user.password = val
    },
    setConfirmPassword (state, val) {
      state.user.confirmPassword = val
    },
    setVerifiedRecaptcha (state, val) {
      state.user.verifiedRecaptcha = val
    },
    setEmployeeSignUpToken (state, token) {
      state.user.employeeSignupToken = token
    },
    resetUser (state) {
      state.user = new UserModel()
    },
    setUser (state, user) {
      state.user = new UserModel(user)
    },
    setUserStep (state, step) {
      state.user.lastCompletedStep = step
    },
    setQuickSetupSteps (state, steps) {
      state.quickSetupSteps = steps
    },
    setPreLaunchUser (state, preLaunchUser) {
      state.user.preLaunchUser = preLaunchUser
    },
    setLandingPageEmailGuid (state, guid) {
      state.landingPageEmailGuid = guid
    }
  },
  actions: {
    setAgreedToTerms (context, val) {
      context.commit('setAgreedToTerms', val)
    },
    setCompanyName (context, val) {
      context.commit('setCompanyName', val)
    },
    setCompanyWebsite (context, val) {
      context.commit('setCompanyWebsite', val)
    },
    setWorkingWithSales (context, val) {
      context.commit('setWorkingWithSales', val)
    },
    setFirstName (context, val) {
      context.commit('setFirstName', val)
    },
    setLastName (context, val) {
      context.commit('setLastName', val)
    },
    setEmail (context, val) {
      context.commit('setEmail', val)
    },
    setPassword (context, val) {
      context.commit('setPassword', val)
    },
    setConfirmPassword (context, val) {
      context.commit('setConfirmPassword', val)
    },
    setEmployeeSignUpToken (context, token) {
      context.commit('setEmployeeSignUpToken', token)
    },
    setPreLaunchUser (context, val) {
      context.commit('setPreLaunchUser', val)
    },
    resetUser (context) {
      context.commit('resetUser')
    },
    async verifyRecaptcha (context, payload) {
      let reset = false
      let res = false
      if (typeof payload === 'object') reset = payload.reset
      if (!reset) res = await UserService.verifyRecaptcha(payload)
      context.commit('setVerifiedRecaptcha', res.Success)
      return res
    },
    async register (context, payload) {
      const response = await UserService.register(payload)
      const success = response.Success
      const user = response.PayLoad
      if (!success) toastr.error(response.Error)
      if (success && context.state.user.preLaunchUser) UserService.markUserPrelaunch()
      context.commit('setUser', user)
      context.commit('setAuthentication', success, {root: true})
      return response
    },
    async forgotPassword (context, payload) {
      const user = await UserService.forgotPassword(payload)
      if (user) {
        return true
      } else toastr.error(user.Error)
    },
    async newPassword (context, payload) {
      const errMessage = 'The Reset Link has expired. Please click Forgot Password again.'
      const user = await UserService.newPassword(payload)
      // TODO : change the endpoint to return Bool not String.
      switch (user) {
        case 'success' :
          return true
        case 'error' :
          toastr.error(errMessage)
          return false
        default :
          toastr.error(errMessage)
          return false
      }
    },
    async login (context, payload) {       
      const user = await UserService.login(payload)
      const success = user ? user.Success : false
      if (!success) toastr.error(user.PayLoad || 'Incorrect Email or Password.')
      context.commit('setAuthentication', success, {root: true})
      context.commit('setUser', user)
      return success
    },
    async logout (context, payload) {
      const success = await UserService.logout(payload)
      if (!success) toastr.error('Logout Error.')
      return success
    },
    async getUser (context, payload) {
      let user = context.state.user
      let preLaunchUser = context.state.user.preLaunchUser
      if (user.isDefault()) {
        const newUser = await UserService.getLoggedInAndImpersonatedUser()
        if (newUser) {
          const mappedUser = mapLoggedInAndImpersonatedUserFromApi({...newUser, preLaunchUser})
          context.commit('setUser', mappedUser)           
        }
        return user.onboardingStatus()
      }
      return true
    },
    async setUserStep (context, step, retryCount = 0) {
      if (retryCount > 3) {
        return { Success: true }
      }
      var success = await UserService.setUserStep(step)
      if (!success || !success.Success) {
        success = this.setUserStep(context, step, retryCount + 1)
      }
      if (success) context.commit('setUserStep', step)
      return success
    },
    async upsertLandingPageEmail (context) {
      const response = await UserService.upsertLandingPageEmail({
        Id: context.state.landingPageEmailGuid,
        Email: context.state.user.email.value
      })
      context.commit('setLandingPageEmailGuid', response)
    },
    async removeLandingPageEmail (context) {
      if (!context.state.landingPageEmailGuid) return
      UserService.removeLandingPageEmail({
        Id: context.state.landingPageEmailGuid
      })
    },
    async getQuickSetupSteps (context) {
      const response = await UserService.getQuickSetupSteps()
      if (response) {
        let quickSetupSteps = []
        response.data.forEach(step => {
          quickSetupSteps.push(new QuickSetupStep(step))
        })
        context.commit('setQuickSetupSteps', quickSetupSteps)
      }
    }
  },
  getters: {
    getUser: state => state.user,
    getUserForAnalytics: state => state.user.mapForAnalytics(),
    getUserForAdminPage: state => state.user.mapForAdminPage(),
    getQuickSetupSteps: state => state.quickSetupSteps
  }
}
