<template>
  <div class="container-fluid flex-full-height">
    <div class="flex-1 d-flex">
      <div id="hero-image-wrapper" class="row">
        <div class="col-12 flex-full-height px-75 py-50 setup-guide-hero">
          <div class="flex-full-height">
            <div class="flex-1 d-flex flex-column">
              <div class="row">
                <h1 class="hero-header bold font-weight-bold line-height-56">
                   
                  <br /> 
                </h1>
                <h2
                  class="hero-header-2"
                > </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="setup-guide-login-wrapper" class="row">
        <div
          class="form-container col-12 flex-full-height justify-content-end px-20 px-l-40 px-xxl-100 py-30"
        >
          <div class="row flex-1 flex-column justify-content-center">
             <vs-row  vs-w="12" vs-type="flex" vs-justify="space-around"> 
               <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5"    >
               <img
              class="shipbob-logo"
              :src="require('../assets/CartBob-Logo-WName-Color.png')" 
            />  
               </vs-col>
             <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2"  >
              <h4 class="subtitle plus">+</h4>
             </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5"    >
               <img
              class="shopify-logo"
              :src="require('../assets/shopify.png')" 
            />              
            </vs-col>
            </vs-row>
             <h4 class="subtitle">Now link your Shopify Store below: </h4>
             <sb-form>
                <sb-field>
                  <sb-label>*Note* You should be logged into your Shopify store before installing.</sb-label>
                  <!-- <vs-row>
                    <vs-col>
                  <sb-label>https://</sb-label><sb-text-input v-model="storeName" placeholder="your store name" ></sb-text-input><sb-label>.myshopify.com</sb-label></vs-col>
                 </vs-row> -->
                </sb-field>
                <!-- <div><br></div>
                  
                 <sb-button @click="linkName" variation="secondary" size="medium" @click.prevent>Install CartBob App</sb-button> -->
           </sb-form>

            <sb-table
    :columnDefinitions="columnDefinitions"
    :rows="tableHelper.pagedData"
    :loadingPromise="loadingPromise"
    @sort="handleSort"
    :defaultSortColumn="defaultSortColumn"
    :defaultSortOrdering="defaultSortOrdering"
  > 
    <sb-table-row slot="sb-table-row" slot-scope="{row}"> 
      <sb-table-cell>{{row.name}}</sb-table-cell> 
 <sb-table-cell>                 <sb-button @click="link(row.name)" variation="secondary" size="medium" @click.prevent>Install CartBob</sb-button>
</sb-table-cell> 
    
    </sb-table-row>
    <sb-table-empty-state
      slot="sb-table-empty-state"
      icon-name="users"
      label="No Stores to link"
    >
      <p> </p>
    </sb-table-empty-state>
   
  </sb-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script> 

import toastr from "toastr";
import axiosInstance from "../helpers/ws"; 
import moment from "moment";
import Vue from "vue";
import tabler from "@/utils/tablehelper"; 
import PaginationWrapper from "@/components/utils/PaginationWrapper";

export default {
  data() {
    return { 
      storeName:"",
      loadingPromise: null,
      dataSelected: [],    
      loading: false,
      helper:tabler,
      tableHelper: new tabler(this, 25,"CLIENTSIDE"),   
      columnDefinitions: [ 
         { name: "Store Name", field: "",sortable: false },         
        { name: "Action", field: "" , sortable: false }      
      ]   
    };
  },
  mounted() {      
     this.update();
  },
  computed: {
    defaultSortColumn() {
      return null;
    },
    defaultSortOrdering() {
      return null;
    } 
  },
  methods: {
     handleSort({ sortColumn, descending }) {
      this.tableHelper.handleSort({
        sortColumn: sortColumn,
        descending: descending
      });
    },
     
    map(item) {
      return item; 
    },
     
    handleFetchPage(p) { 
        this.loadingPromise = this.fetch(p.page); 
    },
     fetch: async function(p) {
      await new Promise(res => {
         
        this.loading = true;

        this.dataSelected = [];
        this.tableHelper.preLoadReset(); 

        let config = {
         
        };
   
        axiosInstance(
          "GET",
          process.env.VUE_APP_ENDPOINT + "auth/channel",
          config
        ).then(response => { 
          this.dataSelected = response.data.data.filter(f=> f.name!=undefined && f.application_name!=undefined &&  (f.name.toLowerCase().includes("shopify") || f.application_name.toLowerCase().includes("shopify")));
          this.tableHelper.loadData( this.dataSelected,null, p);
        
          this.loading = false; 
          res(true);
        });
    
      });
    }
    ,
    update() {
      this.loadingPromise = this.fetch(1); 
    } 
    ,
     linkName()
     {
        if(this.storeName==undefined||this.storeName=='')
         {
           toastr.error('Enter a valid store name');
           return;
         }
         link(storeName);
     },
       link(storeName)
       {
         if(storeName==undefined||storeName=='')
         {
           toastr.error('Enter a valid store name');
           return;
         }
           axiosInstance(
          "GET",
          process.env.VUE_APP_ENDPOINT + "install?storeName="+storeName,
          null
        ).then(response => {           
           this.loginLink=response.data;
           window.location.replace(this.loginLink);
        });
       }
  }
};
</script>
<style scoped lang='scss'> 
.shipbob-logo
{
width: 103%;
}
.shopify-logo
{
width: 43%;
}
.plus{
  font-size: 2.3rem;
}
</style>