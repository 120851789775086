<template>
    <div class="accotation-item">
        <vs-row vs-justify="center">
            <vs-col class="centered" vs-justify="center">
                <div :class="'doughnut ' + color" v-if="this.enabled"></div>
                <div class='doughnut disabled' v-else></div>

                <h4 class="accotation-title">{{title}}</h4>
                <span v-if="showPercent==undefined"> {{percentage}} %</span>
                <span v-if="showPercent"> $ {{numberWithCommas(percentage)}}</span>
                <div class="form-group">
                    <select class="form-control select-acotation" label="Figuras" v-model="selectedItem" v-if="requiredDropdown" @input="toggleAccotation" :active="isActive">
                        <option :key="index" :value="item.value" :vs-text="item.text" v-for="(item,index) in items">{{item.text}}</option>>
                    </select>
                    <div v-else>
                        <!-- <p>&nbsp;</p> -->
                        <!-- <p>&nbsp;</p> -->
                    </div>
                </div>
                <!-- <vs-select class="accotation-select" label="Figuras" v-model="selectedItem" v-if="requiredDropdown" @input="toggleAccotation" :active="isActive">
                    <vs-select-item :key="index" :vs-value="item.value" :vs-text="item.text" v-for="(item,index) in items"/>
                </vs-select> -->
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
export default {
    props:['color', 'title', 'percentage', 'requiredDropdown', 'showPercent'],
    data() {
        return {
            isActive: false,
            enabled: true,
            selectedItem:0,
            items: [{
                value:1,
                text: "OFF"
            },
            {
                value:0,
                text: "ON"
            }]
        }
    },
    methods: {
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        toggleAccotation() {
            this.isActive = false;
            
            if(this.selectedItem === 0) {
                this.enabled = false;
            } else {
                this.enabled = true;
            }

            let varName = "";


            if(this.title !== undefined){
                varName = 'setideal_' + this.title.toLowerCase();
            } else {
                varName = "default"
            }
            this.$store.commit(varName, this.selectedItem);

            this.$root.$emit("accotationChanged");
        }
    } 
}
</script>
